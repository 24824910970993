import L from "leaflet";
import findIndex from "lodash/findIndex";
import {
  LISAG_SERVER_BASE_URL,
  LISAG_REST_BASE_URL,
  BUND_SERVER_BASE_URL,
  BUND_METADATA_BASE_URL,
  BUND_LEGEND_URL
} from "./ENV";
import { maps } from "../utils/mapStateUtil";
import { updateGroup } from "../toc/layerGroupActions";
import { updateLayer } from "../toc/layerActions";

let visibleLayers = [];

const checkLayerIndex = (visibleLayers, layername) => {
  return findIndex(visibleLayers, element => {
    return element.name === layername;
  });
};

/**
 * Creates umlaute from certain letter combinations in a string.
 * @param {string} string to replace with umlauten.
 * @return {string} that contains umlaute.
 */
export const getUmlaute = string => {
  const Ä = string.replace(/AE/g, "Ä");
  const ä = Ä.replace(/ae/g, "ä");
  const Ö = ä.replace(/OE/g, "Ö");
  const ö = Ö.replace(/oe/g, "ö");
  const Ü = ö.replace(/UE/g, "Ü");
  return Ü.replace(/ue/g, "ü");
};

/**
 * filter the layergroups from the reducer, by those who are affected by the apo perimeter.
 * @param {object} affectedLayers Object like delivered from "geoserver_layers" attribute.
 * @param {object} layerGroups The layergroups from the reducer.
 * @return {array} all the reducer layergroups that are affected by the perimeter.
 */
export const filterLayerGroups = (affectedLayers, layerGroups) => {
  return layerGroups.allIds
    .filter(id => {
      return affectedLayers[id];
    })
    .map(id => layerGroups.byId[id]);
};

/**
 * filter the sublayers in the affected layergroups, by those who are delivered from the geodata.
 * @param {array} groups Layergroup Objects as returned from filterLayerGroups()
 * @param {object} affectedLayers Object like delivered from "geoserver_layers" attribute.
 * @return {array} of layergroups with filtered sublayers.
 */
export const filterSubLayers = (groups, affectedLayers) => {
  return groups.map(group => {
    group.sublayers = affectedLayers[group.id];
    return group;
  });
};

/**
 * Creates Layer Objects (like stored in the reducer) from a layername.
 * @param {array} layernames - array of layername strings.
 * @param {object} layersById Object like stored in the layers reducer.
 * @return {array} of layer objects.
 */
export const getLayersInGroup = (layerNames, layersById) => {
  return layerNames.map(name => {
    return layersById[name];
  });
};

/**
 * This function returns filterd LayerGroups and corresponding Layers.
 * @param {object} affectedLayers Object like delivered from "geoserver_layers" attribute.
 * @param {object} layerGroups The layergroups from the reducer.
 * @return {array} of layer Objects like stored in the reducer.
 */
export const getAffectedLayers = (affectedLayers, layerGroups) => {
  const filteredGroups = filterLayerGroups(affectedLayers, layerGroups);
  return filterSubLayers(filteredGroups, affectedLayers);
};

/**
 * DispatchToProps function to toggle layergroups and layers.
 * @param {object} layerObject Object as stored in the layerReducer
 * @return {object} leaflet layer object that has been added.
 */
export const layerTogglingDispatch = dispatch => ({
  toggleExpand: group => {
    dispatch(updateGroup({ ...group, expanded: !group.expanded }));
  },

  toggleLayers: (group, layers, on) => {
    if (on) {
      dispatch(
        updateGroup({
          ...group,
          allOn: true,
          visibleLayers: group.sublayers.length //all layers are now visible
        })
      );
      layers.forEach(layer => {
        if (layer.data) {
          addLayer(layer);
          dispatch(updateLayer({ ...layer, visibility: true }));
        }
      });
    } else {
      dispatch(updateGroup({ ...group, allOn: false, visibleLayers: 0 }));
      layers.forEach(layer => {
        if (layer.data) {
          removeLayer(layer);
          dispatch(updateLayer({ ...layer, visibility: false }));
        }
      });
    }
  }
});

/*
 * check if a layer object is from a projeted layer and return the official layer.
 * @param {object} projectedLayer - layer object like stored in the layers reducer
 * @param {object} layers - layers object like stored in reducer.
 * @return {object} the official layer object or null.
 */
export const getOfficialLayer = (projectedLayer, layers) => {
  if (projectedLayer.id.indexOf("proj.") === -1) {
    return null;
  }
  const name = projectedLayer.id.slice(0, projectedLayer.id.length - 6);
  return layers.byId[name];
};

/**
 * function that adds a new layer to the map.
 * @param {object} layerObject Object as stored in the layerReducer
 * @return {object} leaflet layer object that has been added.
 */
export const addLayer = layerObject => {
  // only add the layer if it is not allready on the map
  if (checkLayerIndex(visibleLayers, layerObject.geoserverLayerName) === -1) {
    const layer = createLefleatLayer(layerObject);
    const addedLayer = layer.addTo(maps[0]);
    addedLayer.name = layerObject.geoserverLayerName;
    visibleLayers.push(addedLayer);
    // @TODO create the new layer string and update the url
    return addedLayer;
  }
};

/**
 * creates a lefleat layer object from an object like stored in the layerReducer.
 * @param {object} layerObject Object as stored in the layerReducer
 * @return {object} leafletLayer Leaflet layer object ready to add to the map.
 */
export const createLefleatLayer = layerObject => {
  const {
    layerBund,
    geoserverLayerName,
    geoserverNamespace,
    opacity,
    position
  } = layerObject;
  let lefleatLayer = {};
  const serviceUrl = layerBund ? BUND_SERVER_BASE_URL : LISAG_SERVER_BASE_URL;
  const layers = layerBund
    ? geoserverLayerName
    : geoserverNamespace + ":" + geoserverLayerName;
  const attribution = layerBund
    ? "&copy; Geodaten: Swisstopo"
    : "&copy; Geodaten: Lisag AG";

  lefleatLayer = L.tileLayer.wms(serviceUrl, {
    layers: layers,
    format: "image/png",
    transparent: true,
    opacity: opacity / 100,
    zIndex: position,
    maxZoom: 21,
    attribution: attribution
    //tiled: true
  });

  return lefleatLayer;
};

/**
 * removes a certain layer from the map.
 * @param {object} layerObject Object as stored in the layerReducer.
 * @return {object} layerToRemove The layer that has been removed.
 */
export const removeLayer = layer => {
  const index = checkLayerIndex(visibleLayers, layer.geoserverLayerName);
  if (index > -1) {
    const layerToRemove = visibleLayers[index];
    // remove the layer from the map and from the visibleLayers array.
    layerToRemove.remove();
    visibleLayers.splice(index, 1);
    return layerToRemove;
  }
};

/**
 * toggles a layer on or off.
 * @param {object} layerObject Object as stored in the layerReducer
 * @return {Promise Object}
 */
export const toggleLayer = layerObject => {
  return new Promise((resolve, reject) => {
    if (layerObject.visibility) {
      removeLayer(layerObject);
      resolve("layer removed");
    } else {
      addLayer(layerObject);
      resolve("layer added");
    }
  });
};
/**
 * changes a layers transparency.
 * @param {object} layerObject Object as stored in the layerReducer.
 * @param {string} transparency The new transparency value to set.
 * @return {Promise Object}.
 */
export const setTransparency = (layerObject, transparency) => {
  const index = checkLayerIndex(visibleLayers, layerObject.geoserverLayerName);
  if (index > -1) {
    return new Promise((resolve, reject) => {
      if (layerObject.visibility === false) {
        resolve("layer is not on");
      } else {
        const leafletLayer = visibleLayers[index];
        leafletLayer.setOpacity(transparency / 100);
        resolve("transparency set");
      }
    });
  }
};

/**
 * checks if all layers in a group are turned on.
 * @param {array} layers Array of layer objects.
 * @return {boolean} true if all layers are turned on, false otherwise.
 */
export const checkAllTurnedOn = layers => {
  const onLayers = layers.filter(layer => layer.visibility === true);
  return onLayers.length === layers.length ? true : false;
};

/**
 * extract a reducer layer object based on it's geoserverLayerName.
 * @param {object} layer object like stored in the layers reducer
 * @param {string} geoserver layername.
 * @return {object} the layer object or undefined.
 */
export const layerObjectByName = (layers, name) => {
  return layers.allIds
    .map(id => {
      if (layers.byId[id].geoserverLayerName === name) {
        return layers.byId[id];
      } else {
        return undefined;
      }
    })
    .filter(object => typeof object !== "undefined")[0];
};

/**
 * gets metadata about a Lisag layer.
 * @param {object} layer like stored in the layers reducer
 * @return {Promise}.
 */
export const getLisagLayerMetadata = layer => {
  const { geoserverLayerName, geoserverNamespace } = layer;
  const url =
    LISAG_REST_BASE_URL +
    `metadataFromGeoserverLayerName/geoserverLayerName/${geoserverLayerName}/format/json`;
  return fetch(url)
    .then(response => response.json())
    .then(json => {
      // get the date of the last update
      return fetch(
        `https://geo.ur.ch/rest/api/geour/akt_datum/namespace/${
          json[0].namespace_geoserver
        }/table/${json[0].DATENSATZ_EXTRAKTION_ZEITSTAND}/format/json`
      )
        .then(response => response.json())
        .then(zeitstand => {
          return {
            name: json[0].NAME_OFFIZIELL,
            id: json[0].NR_GEOBASISDATENSATZ,
            genauigkeit: json[0].ERFASSUNGSGENAUIGKEIT,
            aktualisierung: json[0].AKTUALITAET,
            zeitstand: zeitstand[0].datum_upload_sde.slice(0, 19),
            beschrieb: json[0].KURZBESCHRIEB,
            legende: `https://service.lisag.ch/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&height=15&LAYER=${geoserverNamespace}:${geoserverLayerName}&legend_options=forceLabels:on`
          };
        });
    })
    .catch(error => Promise.reject(error.message));
};

/**
 * gets metadata about a geoserver or swisstopo layer.
 * @param {object} layer like stored in the layers reducer
 * @return {Promise}.
 */
export const getBundLayerMetadata = layer => {
  const { geoserverLayerName } = layer;
  if (!geoserverLayerName) {
    return Promise.resolve({
      name: layer.id,
      beschrieb: "Kein Beschrieb verfügbar.",
      legende: "Keine Legende verfügbar."
    });
  }
  const url = BUND_METADATA_BASE_URL + geoserverLayerName;
  return fetch(url)
    .then(response => response.json())
    .then(json => {
      return {
        name: json.layer.title[0],
        beschrieb: json.layer.abstract[0],
        legende:
          BUND_LEGEND_URL +
          `${geoserverLayerName}&format=image/png&STYLE=default`
      };
    })
    .catch(error => Promise.reject(error.message));
};

/**
 * Create an array of "ns:layername" strings of all the turned on layers.
 * @param {array} layers array of layer objects.
 * @return {array} result - strings in in format: ns:geoserverLayerName.
 */
export const getOnLayers = layers => {
  const result = [];
  layers.forEach(layer => {
    result.push(`${layer.geoserverNamespace}:${layer.geoserverLayerName}`);
  });
  return result;
};
