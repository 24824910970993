import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Segment, Image } from "semantic-ui-react";
import paragraph from "../assets/img/paragraph.png";
import MetadataContent from "./MetadataContent";
const styles = {
  modal: {
    maxWidth: "600px"
  },
  header: {
    textAlign: "center",
    backgroundColor: "#f9fafb"
  },
  info: {
    color: "gray",
    fontSize: "0.8em",
    paddingTop: "15px"
  },
  layerDivider: {
    margin: "30px 0"
  }
};
const MetadataModal = ({ modal, closeModal }) => {
  return (
    <Modal
      open={modal.modalType !== null}
      closeIcon={true}
      onClose={closeModal}
      style={styles.modal}
    >
      <Modal.Header data-testid="modal-header" style={styles.header}>
        <h2>LAYER INFORMATIONEN</h2>
      </Modal.Header>
      <Modal.Content>
        {modal.modalProps.length === 0 && (
          <Segment loading>
            <Image src={paragraph} />
          </Segment>
        )}
        {modal.modalProps.length > 0 &&
          modal.modalProps.map((metadata, index) => (
            <div key={index}>
              <MetadataContent metadata={metadata} />
              {index !== modal.modalProps.length - 1 && (
                <hr style={styles.layerDivider} />
              )}
            </div>
          ))}
      </Modal.Content>
      <Modal.Actions>
        <Button data-testid="close-button" primary onClick={closeModal}>
          schliessen
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default MetadataModal;

MetadataModal.propTypes = {
  modal: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired
};
