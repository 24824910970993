import React from "react";
import { Segment } from "semantic-ui-react";
const ProjectMetadata = ({ project }) => {
  return (
    <Segment secondary>
      <div className="metadata__container">
        <div className="metadata__item">
          <h5 className="metadata__title">RECHTSSTATUS</h5>
          <div className="metadata__text">
            {project.properties.projrechtsstatus}
          </div>
        </div>
        <div className="metadata__item">
          <h5 className="metadata__title">BESCHLUSS</h5>
          {project.properties.beschlussname && (
            <div className="metadata__text">
              Name: {project.properties.beschlussname}
            </div>
          )}
          {project.properties.beschlussdatum && (
            <div className="metadata__text">
              Datum: {project.properties.beschlussdatum.slice(0, 10)}
            </div>
          )}
          {project.properties.beschlussnr && (
            <div className="metadata__text">
              Nr: {project.properties.beschlussnr}
            </div>
          )}
          {project.properties.beschlussbehoerde && (
            <div className="metadata__text">
              Behörde: {project.properties.beschlussbehoerde}
            </div>
          )}
          {project.properties.beschlusslink && (
            <div className="metadata__text">
              <a
                className="metadata__link"
                href={project.properties.beschlusslink}
              >
                Link
              </a>
            </div>
          )}
        </div>
        <div className="metadata__item">
          <h5 className="metadata__title">ÖREB-THEMA</h5>
          <div className="metadata__text">{project.properties.oerebthema}</div>
        </div>
        <div className="metadata__item">
          <h5 className="metadata__title">ZUSTÄNDIGE STELLE</h5>
          <div className="metadata__text">
            {project.properties.zustaendigestelle}
          </div>
        </div>
        {project.properties.rechtsvorschriften && (
          <div className="metadata__item">
            <h5 className="metadata__title">RECHTSVORSCHRIFTEN & DOKUMENTE</h5>
            <div className="metadata__text">
              <a
                className="metadata__link"
                href={project.properties.rechtsvorschriften}
              >
                Link
              </a>
            </div>
          </div>
        )}
      </div>
    </Segment>
  );
};

export default ProjectMetadata;
