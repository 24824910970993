import React from "react";
import { Button } from "semantic-ui-react";
import PropTypes from "prop-types";

const StateSwitch = props => {
  const { apo, switchApoState, device } = props;
  if (device === "extraSmall" || device === "small") {
    return (
      <Button
        className="apo-state-switch"
        data-testid="state-switch"
        primary
        circular
        icon="sign-out"
        title="Zum rechtskräftigen Zustand"
        onClick={() => switchApoState(!apo)}
      />
    );
  }
  return (
    <Button
      data-testid="state-switch"
      basic
      primary
      onClick={() => switchApoState(!apo)}
      title="Zum rechtskräftigen Zustand"
    >
      Zum ÖREB-Kataster
    </Button>
  );
};

export default StateSwitch;

StateSwitch.propTypes = {
  apo: PropTypes.bool.isRequired,
  switchApoState: PropTypes.func.isRequired,
  device: PropTypes.string.isRequired
};
