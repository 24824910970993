export const SET_APO = "SET_APO";
export const SET_APO_POLYGONS = "SET_APO_POLYGONS";

export const setApo = value => ({
  type: SET_APO,
  value
});

export const setApoPolygons = apoPolygons => ({
  type: SET_APO_POLYGONS,
  apoPolygons
});
