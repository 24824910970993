import React from "react";
import { connect } from "react-redux";
import ApoSearchContainer from "../search/ApoSearchContainer";
import AuflageMap from "./AuflageMap";
import ProjectMetadata from "./ProjectMetadata";
import PerimeterList from "./PerimeterList";
import urlUtil from "../utils/urlUtil";
import { getWfsResult } from "../utils/requestUtil";
import {
  getAffectedLayers,
  getLayersInGroup,
  getOfficialLayer,
  getUmlaute
} from "../utils/layerUtil";
import {
  syncMaps,
  addParcelToAuflageMaps,
  getName
} from "../utils/auflageUtil";
import { maps } from "../utils/mapStateUtil";
import { setApo } from "../apo/apoActions";
import { showModal } from "../modal/modalActions";
import { Segment } from "semantic-ui-react";
import "./Auflage.css";

const styles = {
  auflageContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "24px",
    textAlign: "center"
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "12px"
  },
  errorContainer: {
    padding: "24px"
  }
};

class AuflageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { perimeter: 0 };
  }
  componentDidMount() {
    // make sure apo state gets activated.
    this.props.setApo(true);
    document.title = "Kanton Uri: APO";
    //empty maps array
    maps.length = 0;
    const searchParams = urlUtil.getCurrentParams();
    if (searchParams.egrid) {
      this.setState({ egrid: searchParams.egrid });
    }

    // If there is no perimeter searchparam,
    // filter ALL the perimeters to only have the most recent ones.
    if (!searchParams.perimeter) {
      getWfsResult("raumplanung:apo_perimeter")
        .then(perimeters => {
          const aktPeris = perimeters.features.filter(perimeter => {
            const aktStatusArr = perimeter.properties.aktuellerrechtsstatus.split(
              "."
            );
            const aktuellerStatus = aktStatusArr[aktStatusArr.length - 1];
            if (aktuellerStatus === perimeter.properties.projrechtsstatus) {
              return true;
            } else {
              return false;
            }
          });
          this.setState({ periList: aktPeris });
          return;
        })
        .catch(error => this.setState({ errorMessage: error.toString() }));
    }
    this.setState({ perimeter: searchParams.perimeter });
    getWfsResult(
      "raumplanung:apo_perimeter",
      "unique_key",
      searchParams.perimeter
    )
      .then(perimeter => {
        const periProps = perimeter.features[0].properties;
        // replace ae, ue... with ä, ü....
        periProps.geoserver_layers = getUmlaute(periProps.geoserver_layers);
        const layergroups = getAffectedLayers(
          JSON.parse(periProps.geoserver_layers),
          this.props.groupLayers
        );

        this.setState({
          layerGroups: layergroups,
          completeLayerGroups: getSortedLayerGroups(
            layergroups,
            this.props.layers
          ),
          perimeter: perimeter.features[0],
          datumvon: perimeter.features[0].properties.datumvon
            ? new Date(perimeter.features[0].properties.datumvon.slice(0, 10))
            : "",
          datumbis: perimeter.features[0].properties.datumbis
            ? new Date(perimeter.features[0].properties.datumbis.slice(0, 10))
            : "",
          projektname: periProps.projektname,
          rechtsstatus: periProps.projrechtsstatus
        });
        // syncronize the maps after all maps are added to the maps Array
        const mapCount = this.state.layerGroups.length * 2;
        syncMaps(maps, mapCount);

        // if there is a egrid url param, request the parcel and add it to every map.
        if (this.state.egrid) {
          getWfsResult(
            "suche:all_egrid_data",
            "egris_egrid",
            this.state.egrid
          ).then(parcel =>
            addParcelToAuflageMaps({
              maps: maps,
              expectedLength: mapCount,
              geojson: parcel
            })
          );
        }
      })
      .catch(error => this.setState({ errorMessage: error.toString() }));
  }
  render() {
    if (this.state.periList) {
      return <PerimeterList perimeters={this.state.periList} />;
    }
    if (this.state.errorMessage) {
      return (
        <div style={styles.errorContainer}>
          <h2>
            {" "}
            Ups, etwas ging schief{" "}
            <span role="img" aria-label="thinking face">
              🤔
            </span>
          </h2>
          <h3>{this.state.errorMessage}</h3>
          <h3>
            Bitte melden Sie sich bei der Lisag AG.
            <br />
            Tel.: 041 500 60 60
          </h3>
        </div>
      );
    }

    if (this.state.layerGroups) {
      return (
        <div style={{ padding: "20px" }}>
          <h1 className="metadata__header">
            {this.state.perimeter.properties.projrechtsstatus}{" "}
            <span style={{ fontWeight: "normal" }}>
              {this.state.perimeter.properties.projektname}
            </span>
          </h1>

          {this.state.datumvon && this.state.datumbis && (
            <h3 className="metadata__date">
              {Date.now() < this.state.datumbis && (
                <span>
                  Innerhalb der Auflagefrist (bis{" "}
                  {this.state.datumbis.toLocaleDateString()}) können
                  schriftliche Einsprachen mit bestimmten Begehren und begründet
                  beim Gemeinderat eingereicht werden (Art. 43 Abs. 2 PBG).
                </span>
              )}

              {Date.now() > this.state.datumbis && (
                <span>
                  Die öffentliche Auflage fand statt vom{" "}
                  {this.state.datumvon.toLocaleDateString()} bis{" "}
                  {this.state.datumbis.toLocaleDateString()}.{" "}
                  <span className="red">
                    Die Einsprachefrist ist abgelaufen.
                  </span>
                </span>
              )}
            </h3>
          )}
          <hr />
          <ProjectMetadata project={this.state.perimeter} />
          <ApoSearchContainer />
          <div className="compare__container">
            <div className="compare__title">Projektierter Zustand</div>
            <div className="compare__title">Rechtskräftiger Zustand</div>
          </div>
          <div className="comparemaps">
            {this.state.completeLayerGroups.map(layergroup => {
              return (
                <AuflageMap
                  key={layergroup.id}
                  layergroup={layergroup}
                  perimeter={this.state.perimeter}
                  displayMetadata={this.props.displayMetadata}
                />
              );
            })}
          </div>
          <Segment secondary size="large">
            <span className="metadata__text">
              Eine{" "}
              <a href={`${document.location.origin}/auflage`}>
                Liste aller Projekte
              </a>{" "}
              finden Sie
              <a href={`${document.location.origin}/auflage`}> hier.</a>
            </span>
          </Segment>
        </div>
      );
    }
    return <h2 style={{ padding: "20px" }}>Die Karten werden geladen....</h2>;
  }
}
const mapDispatchToProps = dispatch => ({
  setApo: val => dispatch(setApo(val)),
  displayMetadata: metadata => dispatch(showModal("layerMetadata", metadata))
});
const mapStateToProps = ({ layers, groupLayers }) => ({ layers, groupLayers });
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuflageContainer);

/*
 @description: create an array with all the layergroups and add
   the layergroups in the order projektiert/rechtskräftig for every group.
  @param {array} layergroups - the affected layegroups of this auflage like stored in layergroups reducer.
  @param {object} layers - all the layers like stored in the layers reducer.
  @return {array} sortedLayerGroups - the layergroups in the order projektiert/rechtskräftig
*/
const getSortedLayerGroups = (layergroups, layers) => {
  const sortedLayerGroups = [];
  layergroups.forEach(layergroup => {
    const offLayerGroup = Object.assign({}, layergroup);
    const projLayers = getLayersInGroup(layergroup.sublayers, layers.byId);
    const offLayers = projLayers.map(layer => getOfficialLayer(layer, layers));
    const projGroupName = getName({
      name: layergroup.id,
      projected: true
    });
    const offGroupName = getName({
      name: layergroup.id,
      projected: false
    });
    layergroup.layers = projLayers;
    layergroup.projected = true;
    layergroup.id = projGroupName;
    offLayerGroup.layers = offLayers;
    offLayerGroup.projected = false;
    offLayerGroup.id = offGroupName;
    sortedLayerGroups.push(layergroup, offLayerGroup);
  });
  return sortedLayerGroups;
};
