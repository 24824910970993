import React from "react";
import { Table } from "semantic-ui-react";
const styles = {
  listContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "12px"
  }
};
const PerimeterList = ({ perimeters }) => {
  return (
    <div style={styles.listContainer}>
      <h2>Liste der laufenden Projekte</h2>
      <Table striped color="yellow" size="large">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Projekt</Table.HeaderCell>
            <Table.HeaderCell>ÖREB-Thema</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Zuständige Stelle</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {perimeters.map(perimeter => {
            const {
              unique_key,
              projektname,
              oerebthema,
              projrechtsstatus,
              zustaendigestelle
            } = perimeter.properties;
            return (
              <Table.Row key={unique_key}>
                <Table.Cell>
                  {(projrechtsstatus === "Öffentliche Auflage" ||
                    projrechtsstatus === "Festsetzung" ||
                    projrechtsstatus === "Genehmigung") && (
                    <a
                      href={`${
                        document.location.origin
                      }/auflage?perimeter=${unique_key}`}
                    >
                      {projektname}
                    </a>
                  )}
                  {(projrechtsstatus === "Einleitung" ||
                    projrechtsstatus === "Vorprüfung") && (
                    <span>{projektname}</span>
                  )}
                </Table.Cell>
                <Table.Cell>{oerebthema}</Table.Cell>
                <Table.Cell>{projrechtsstatus}</Table.Cell>
                <Table.Cell>{zustaendigestelle}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
};

export default PerimeterList;
