import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "semantic-ui-react";
import orderMecano from "../assets/img/order_mecano.gif";

const styles = {
  modal: {
    maxWidth: "600px",
    textAlign: "center"
  },
  header: {
    tbackgroundColor: "#f9fafb"
  },
  info: {
    color: "gray",
    paddingTop: "15px"
  },
  modalText: {
    fontSize: "16px"
  }
};

const OrderModal = ({ modal, closeModal }) => {
  return (
    <Modal
      open={modal.modalType !== null}
      closeIcon={true}
      onClose={closeModal}
      style={styles.modal}
    >
      <Modal.Header style={styles.header} data-testid="modal-header">
        {modal.modalProps.title}
      </Modal.Header>
      <Modal.Content style={styles.modalText}>
        {modal.modalType !== "orderFail" && (
          <img src={orderMecano} alt="order mecano" />
        )}
        <h3> {modal.modalProps.thankText}</h3>
        <span data-testid="modal-text">{modal.modalProps.text}</span>
        {modal.modalType !== "orderFail" && (
          <div style={styles.info}>
            Dieser Vorgang kann bis zu 30 Sekunden dauern.
          </div>
        )}
        {modal.modalProps.exception && (
          <div data-testid="exception">
            <hr />
            <strong>
              <span role="img" aria-label="exclamation">
                ❗
              </span>
              Fehlermeldung:
              <span role="img" aria-label="exclamation">
                ❗
              </span>
            </strong>
            <br />
            {modal.modalProps.exceptionType}
            <br />
            {modal.modalProps.exception}
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={closeModal} data-testid="close-button">
          schliessen
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default OrderModal;

OrderModal.propTypes = {
  modal: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired
};
