export const LISAG_SERVER_BASE_URL =
  //"https://{s}.service.lisag.ch/gwc/service/wms";
  "https://{s}.service.lisag.ch/wms"; //pure wms, no gwc
export const BUND_SERVER_BASE_URL = "https://wms.geo.admin.ch/?";
export const BUND_METADATA_BASE_URL =
  "https://europe-west1-oereb-uri.cloudfunctions.net/getwmsinfo?layername=";
export const LISAG_REST_BASE_URL = "https://geo.ur.ch/rest/api/geour/";
export const BUND_LEGEND_URL =
  "https://wms.geo.admin.ch/?version=1.0.0&service=WMS&request=GetLegendGraphic&layer=";

export const WFS_BASE_URL =
  "https://service.lisag.ch/wfs?version=1.0.0&request=GetFeature&srsName=EPSG:4326";
export const GET_FEATURE_INFO_BASE_URL =
  "https://service.lisag.ch/wms?VERSION=1.1.1&REQUEST=GetFeatureInfo&SERVICE=WMS&EXCEPTIONS=application%2Fvnd.ogc.se_xml";

//Oereb Query URL's
export const PDF_REQUEST_BASE_URL =
  //"https://oereb-ur-proc.dev.geocentrale.com/oereb/extract/reduced/pdf/";
  "https://oereb-ur-proc.pro.geocentrale.com/oereb/extract/reduced/pdf/";
export const JSON_REQUEST_BASE_URL =
  //"https://oereb-ur-proc.dev.geocentrale.com/oereb/extract/reduced/json/";
  "https://oereb-ur-proc.pro.geocentrale.com/oereb/extract/reduced/json/";
