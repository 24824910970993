import "leaflet.sync";
import { maps } from "./mapStateUtil";
import { displayGeojson } from "./geojsonUtil";

/*
 * @description Waits until the maps array is complete/full
 * and then syncs all it's maps
 * @param {array} maps - all the maps from the oeffentliche auflage
 * @param {number} expectedLength - the length the maps array must have to be complete.
 * @return {boolean} true - if maps were synced sucessfully.
 */
export const syncMaps = (maps, expectedLength) => {
  //console.log(maps.length, " / ", expectedLength);
  if (maps.length === expectedLength) {
    synccurrentMap();
    return true;
  }
  requestAnimationFrame(() => {
    syncMaps(maps, expectedLength);
  });
};

/*
 * @description Waits until the maps array is complete/full
 * and then adds a geojson overlay to every map
 * @param {array} maps - all the maps from the oeffentliche auflage
 * @param {number} expectedLength - the length the maps array must have to be complete.
 * @param {object} geojson - a geojson object like received from a wfs request.
 * @return {object} layer - the leaflet geojson layer that was added.
 */
export const addParcelToAuflageMaps = ({
  maps = [],
  expectedLength = 0,
  geojson = {}
} = {}) => {
  if (maps.length === expectedLength) {
    displayGeojson({
      geojson: geojson.features[0],
      maps: maps,
      flyToBounds: true,
      removeExisting: false
    }).then(layer => {
      return layer;
    });
  } else {
    requestAnimationFrame(() => {
      addParcelToAuflageMaps({
        maps: maps,
        expectedLength: expectedLength,
        geojson: geojson.features[0]
      });
    });
  }
};

/*
 * @description Generates a map name based on how many overlay layers are displayed.
 * @param {string} name - The name of the layergroup.
 * @param {array} layers - The sublayers of the layergroup.
 * @param {boolean} proj - Whether it's a map for projected layers or not.
 * @return {string} - The map name. If it's only 1 layer in the layers array, the name of that layer, otherwise the name of the group.
 */
export const getName = options => {
  const { name, projected } = options;
  return projected ? name : name.slice(0, name.length - 12) + " RECHTSKRÄFTIG";
};

/**
 * @description syncronises the maps in the auflage view.
 * @return {boolean} true when sync was sucessfull, false otherwise.
 */
const synccurrentMap = () => {
  const length = maps.length;
  if (length % 2 !== 0) {
    return false;
  }
  for (var i = 0; i < length; i++) {
    // forward sync
    let j = i + 1;
    while (j < length) {
      //console.log(`map ${i} synced with map${j}`);
      maps[i].sync(maps[j]);
      j++;
    }

    // backward sync
    let x = i - 1;
    while (i !== 0 && x >= 0) {
      //console.log(`map ${i} synced with map${x}`);
      maps[i].sync(maps[x]);
      x--;
    }
  }
  return true;
};
