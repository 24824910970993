export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const SET_SIDEBAR_TAB = "SET_SIDEBAR_TAB";

export const toggleSidebar = visible => ({
  type: TOGGLE_SIDEBAR,
  visible
});

export const setSidebarTab = tab => ({
  type: SET_SIDEBAR_TAB,
  tab
});
