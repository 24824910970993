/**
 * Add multiple dom nodes to a parent node.
 * like the native append() method but works in ie11.
 * @param {node} parent - the node to append the children.
 * @param {array} children - the children nodes to append.
 * @return {domNode} parent
 */
export const appendChildren = (parent, children) => {
    children.forEach(child => {
        parent.appendChild(child);
    });
    return parent;
};

/**
 * Add multiple multilple css classes to a dom node.
 * like the native classList.add() method but works in ie11.
 * @param {node} element - the node to add the classes.
 * @param {array} classes - the classes to append.
 * @return {domNode} element
 */
export const addClasses = (element, classes) => {
    classes.forEach(className => {
        element.classList.add(className);
    });
    return element;
};

//attributes to display inside the popup table.
export const visibleAttributes = {
    allIds: [
        "typ_bezeichnung",
        "typ_abkuerzung",
        "typ_kt_bezeichnung",
        "gemeinde",
        "nummer",
        "typ",
        "kantonaletypbezeichnung",
        "bemerkungen",
        "terrintra",
        "projektname",
        "oerebthema",
        "statusaktuell",
        "shape_area"
    ],

    byId: {
        typ_bezeichnung: "Typ Bezeichnung",
        typ_abkuerzung: "Abkürzung",
        typ_kt_bezeichnung: "Kantonale Bezeichnung",
        gemeinde: "Gemeinde",
        nummer: "Nummer Liegenschaft",
        typ: "Typ",
        kantonaletypbezeichnung: "Kantonale Bezeichnung",
        bemerkungen: "Bemerkungen",
        terrintra: "Eigentümer",
        projektname: "Projekt",
        oerebthema: "ÖREB-Thema",
        statusaktuell: "Rechtsstatus",
        shape_area: "Fläche"
    }
};
