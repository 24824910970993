import { TOGGLE_SIDEBAR, SET_SIDEBAR_TAB } from "./sidebarActions";

export const sidebarVisible = (state = false, action) => {
  const { type, visible } = action;
  switch (type) {
    case TOGGLE_SIDEBAR:
      return !visible;
    default:
      return state;
  }
};

export const sidebarTab = (state = "layers", action) => {
  const { type, tab } = action;
  switch (type) {
    case SET_SIDEBAR_TAB:
      if (tab === "layers" || tab === "results") {
        return tab;
      } else {
        return "layers";
      }
    default:
      return state;
  }
};
