import queryString from "query-string";

const urlUtil = (() => {
  const model = {
    history: null,
    search: null,
    parsedSearchParams: null
  };

  const controller = {
    /* Initialize the util.
     * @param history - object from react-rouer (this.prop.history)
     * @param search - object from react-router (this.props.location.search)
     */
    init: (history, search) => {
      model.history = history;
      model.search = search;
      model.parsedSearchParams = queryString.parse(search);
    },

    /* Returns the current search params as key:value pairs. */
    getCurrentParams: () => model.parsedSearchParams,

    /* Set new search params in the model
     * @param {object} The new query parameters als key:value pairs.
     */
    setCurrentSearchParams: parsedSearchParams => {
      model.parsedSearchParams = parsedSearchParams;
    },

    /*
     * Update the url parameter based on the parsedSearchParams Object.
     * @param {object} The current query parameters als key:value pairs.
     * @return {string} the new search parameter string.
     */
    updateSearchParams: parsedSearchParams => {
      controller.setCurrentSearchParams(parsedSearchParams);
      const stringParams = queryString.stringify(parsedSearchParams);
      model.history.push({
        search: stringParams
      });
      return stringParams;
    },

    updatePosition: map => {
      const params = controller.getCurrentParams();
      params.lat = map.getCenter().lat;
      params.lng = map.getCenter().lng;
      params.zoom = map.getZoom();
      controller.updateSearchParams(params);
    },

    toOereb: () => {
      model.history.push("/");
      const currentParams = controller.getCurrentParams();
      delete currentParams.projektperimeter;
      controller.updateSearchParams(currentParams);
    },

    toApo: periId => {
      const currentParams = controller.getCurrentParams();
      const egrid = currentParams.egrid ? `&egrid=${currentParams.egrid}` : "";
      periId
        ? model.history.push(`/auflage?perimeter=${periId}${egrid}`)
        : model.history.push("/auflage");
      window.location.reload();
    },
    /**
     * creates a search params object with the values from a geojson properties object.
     * @param {object} properties - (geojson) properties object with a groupname key.
     * @return {object} searchParams - object with the correct key/value pairs.
     */
    getNewSearchParams: properties => {
      const searchParams = controller.getCurrentParams();
      if (properties) {
        switch (properties.groupname) {
          case "Liegenschaften":
          case "Projektierte Liegenschaften":
          case "Selbstaendige Rechte":
            searchParams.egrid = properties.egris_egrid;
            delete searchParams.flurname;
            delete searchParams.gemeinde;
            delete searchParams.adresse;
            delete searchParams.coordinate;
            break;
          case "Flurnamen":
            searchParams.flurname = properties.searchterm;
            delete searchParams.egrid;
            delete searchParams.gemeinde;
            delete searchParams.adresse;
            delete searchParams.coordinate;
            break;
          case "Gemeinde":
            searchParams.gemeinde = properties.searchterm;
            delete searchParams.egrid;
            delete searchParams.flurname;
            delete searchParams.adresse;
            delete searchParams.coordinate;
            break;
          case "Adressen":
            searchParams.adresse = properties.searchterm;
            delete searchParams.egrid;
            delete searchParams.flurname;
            delete searchParams.gemeinde;
            delete searchParams.coordinate;
            break;
          case "coordinate":
            searchParams.coordinate = properties.coordinate;
            delete searchParams.egrid;
            delete searchParams.flurname;
            delete searchParams.gemeinde;
            delete searchParams.adresse;
            break;
          default:
        }
      }
      return searchParams;
    }
  };

  return {
    init: controller.init,
    getCurrentParams: controller.getCurrentParams,
    updateSearchParams: controller.updateSearchParams,
    getNewSearchParams: controller.getNewSearchParams,
    updatePosition: controller.updatePosition,
    removeApo: controller.removeApo,
    toOereb: controller.toOereb,
    toApo: controller.toApo
  };
})();

export default urlUtil;
