import transform from "./coordTransformUtil";
class OerebParser {
  constructor(rawResult) {
    this.rawResult = rawResult.Extract;
    this.parsedResult = {};
  }

  /**
   * parse the not concerned themes
   * into an array and add it to the parsedResult.
   * @return {object} this
   */
  parseNotConcernedTheme() {
    if (this.rawResult.hasOwnProperty("NotConcernedTheme")) {
      if (this.rawResult.NotConcernedTheme.constructor === Array) {
        this.parsedResult.notConcernedThemes = this.rawResult.NotConcernedTheme.map(
          theme => theme.Text.Text
        );
      } else {
        this.parsedResult.notConcernedThemes = [
          this.rawResult.NotConcernedTheme.Text.Text
        ];
      }
    } else {
      this.parsedResult.notConcernedThemes = [
        "Alle verfügbaren Themen betreffen das Grundstück."
      ];
    }
    return this;
  }

  /**
   * parse the themes without data into
   * an array and add it to the parsedResult.
   * @return {object} this
   */
  parseThemeWithoutData() {
    if (this.rawResult.hasOwnProperty("ThemeWithoutData")) {
      if (this.rawResult.ThemeWithoutData.constructor === Array) {
        this.parsedResult.themesWithoutData = this.rawResult.ThemeWithoutData.map(
          theme => theme.Text.Text
        );
      } else {
        this.parsedResult.themesWithoutData = [
          this.rawResult.ThemeWithoutData.Text.Text
        ];
      }
    } else {
      this.parsedResult.themesWithoutData = [
        "Es sind für alle Themen Daten vorhanden."
      ];
    }
    return this;
  }

  /**
   * parse the total area of the parcel to the parsedResult.
   * @return {object} this
   */
  parseTotalArea() {
    this.parsedResult.totalArea = parseInt(
      this.rawResult.RealEstate.LandRegistryArea,
      10
    );
    return this;
  }

  /**
   * parse the name of gemeinde to the parsedResult.
   * @return {object} this
   */
  parseGemeinde() {
    this.parsedResult.gemeinde = this.rawResult.RealEstate.Municipality;
    return this;
  }

  /**
   * parse the number of the parcel to the parsedResult.
   * @return {object} this
   */
  parseParcelNumber() {
    this.parsedResult.nummer = this.rawResult.RealEstate.Number;
    return this;
  }

  /**
   * parse a raw restriction object to a geojson.
   * @return {object} this
   */
  parseRestrictions() {
    let rawRestrictions = this.rawResult.RealEstate.RestrictionOnLandownership;
    // if there is only one restriction, make an array out of it.
    if (!Array.isArray(rawRestrictions)) {
      rawRestrictions = [rawRestrictions];
    }
    this.parsedRestrictions = rawRestrictions.map(restriction =>
      this.restrictionToGeojson(restriction)
    );
    return this;
  }

  /**
   * aggregate the parsed geojsons if they have some common values
   * @return {object} this
   */
  summarizeParsedRestrictions() {
    // empty object for the result
    const aggregatedRestrictions = {};
    this.parsedRestrictions.forEach(element => {
      const theme = element.properties.theme;

      // if the key allready exists
      if (theme in aggregatedRestrictions) {
        //check if image, legend text and url is already available
        let equal = this.checkRestrictionEquality(
          aggregatedRestrictions[theme],
          element
        );

        if (!equal) {
          aggregatedRestrictions[theme].push(element);
        } else {
          // If there is allready an entry with the same type
          // If not already done, a MultiGeometry must be created
          if (equal.geometry.type.indexOf("Multi") === -1) {
            if (equal.geometry.type === "Point") {
              equal.geometry.type = "MultiPoint";
            }
            if (equal.geometry.type === "LineString") {
              equal.geometry.type = "MultiLineString";
            }
            if (equal.geometry.type === "Polygon") {
              equal.geometry.type = "MultiPolygon";
            }
            //create a new geometry Object to fit MultiPolygon specification
            let newGeom = [];
            newGeom.push(equal.geometry.coordinates);
            equal.geometry.coordinates = newGeom;
          }
          //if already a multi geometry
          equal.geometry.coordinates.push(element.geometry.coordinates);
          //summarize the area
          equal.properties.area =
            parseInt(equal.properties.area, 10) +
            parseInt(element.properties.area, 10);

          //summarize the length
          equal.properties.length =
            equal.properties.length + element.properties.length;

          //summarize the points
          equal.properties.points =
            equal.properties.points + element.properties.points;
        }
      } else {
        //if the key isn't there, create it.
        aggregatedRestrictions[theme] = [];

        //Add the parsed restriction
        aggregatedRestrictions[theme].push(element);
      }
    });
    this.parsedResult.restrictions = aggregatedRestrictions;
    return this;
  }

  /**
   * convert a single restriction object to a geojson
   * @param {object} restriction - restriction object from raw result
   */
  restrictionToGeojson(restriction) {
    let geometry = [];
    const geom = this.getGeometryAndType(restriction);

    //if the geometry type is a polygon
    if (geom.type === "gml:Polygon") {
      geometry = this.transformGeometry(geom);
    }

    //if the geometry type is a line
    if (geom.type === "gml:LineString") {
      // if Multiline geometry
      if (geom.geometry instanceof Array) {
        geom.geometry.forEach(line => {
          let lineCoords = line[geom.type]["gml:posList"];
          geometry = geometry.concat(this.transfromStringCoords(lineCoords));
        });
      } else {
        let lineCoords = geom.geometry[geom.type]["gml:posList"];
        geometry = this.transfromStringCoords(lineCoords);
      }
    }

    // if the geometry type is a point
    if (geom.type === "gml:Point") {
      let pointCoords = geom.geometry[geom.type]["gml:pos"];
      let xyArr = pointCoords.split(" ");
      geometry = [
        transform.YToWGS84(parseFloat(xyArr[0])),
        transform.XToWGS84(parseFloat(xyArr[1]))
      ];
    }
    let geoJSON = {
      type: "Feature",
      crs: {
        type: "name",
        properties: {
          name: "urn:ogc:def:crs:OGC:1.3:CRS84"
        }
      },

      geometry: {
        type: geom.type.substr(4),
        coordinates: geometry
      },

      properties: {
        theme: restriction.Theme.Text.Text,
        legendText: this.parseLegendText(restriction),
        status: restriction.Lawstatus.Code,
        area: this.getMaxArea(this.parseTotalArea(), restriction.AreaShare),
        length: this.getLength(restriction),
        points: this.getPoints(restriction),
        legend: restriction.Symbol,
        legalProvisions: this.parseLegalProvisions(restriction),
        geoserverLayer: restriction.Map.extensions.MapExtension.Layername
      }
    };
    return geoJSON;
  }

  /**
   * @description Get the Geometry and the type for a single restriction.
   * @param {Object} restriction - The restriction restriction.
   * @return {Object} - Object with a type and a geometry key and it's values.
   */
  getGeometryAndType(restriction) {
    let geometryType = "";
    let Geometry = null;
    // Polygon
    if (restriction.Geometry.hasOwnProperty("Surface")) {
      // Geometry from Geoserver
      if (restriction.Geometry.Surface.hasOwnProperty("gml:MultiSurface")) {
        Geometry =
          restriction.Geometry.Surface["gml:MultiSurface"]["gml:surfaceMember"];
      } else {
        // Geometry from Bundesservice
        Geometry = restriction.Geometry.Surface;
      }
      //if it's a Multipolygon get the type from the first Object
      if (Geometry instanceof Array) {
        geometryType = Object.keys(Geometry[0])[0]; //gml:Polygon
      } else {
        geometryType = Object.keys(Geometry)[0]; //gml:Polygon
      }
    }

    //Line
    if (restriction.Geometry.hasOwnProperty("Line")) {
      Geometry = restriction.Geometry.Line["gml:MultiCurve"]["gml:curveMember"];
      geometryType = Object.keys(Geometry)[0]; //gml:LineString
    }
    //Point
    if (restriction.Geometry.hasOwnProperty("Point")) {
      Geometry = restriction.Geometry.Point;
      geometryType = Object.keys(Geometry)[0]; //gml:Point
    }
    return { type: geometryType, geometry: Geometry };
  }

  /*
   * @description Parse a XML Geometry Object to a Geojson Geometry Object.
   * @param {Object} geom - The Result from the getGeometryAndType function.
   * @return {Array} result - Geojson Coordinates in WGS84 Projection.
   */
  transformGeometry(geom) {
    let result = [];
    // if there are multiple Polygons (Multipolygon)
    if (geom.geometry instanceof Array) {
      geom.geometry.forEach(geometry => {
        result = result.concat(this.geometryToWGS84(geometry, geom.type));
      });
      return result;
    } else {
      // only 1 Geometry (the normal case)
      return this.geometryToWGS84(geom.geometry, geom.type);
    }
  }

  /*
   * @description Transforms every coordinate of a polygon xml Geometry to wgs84 projection.
   * @param {Object} geometry - A geometry Object from a xml Request
   * @return {Array} result - Geojson Coordinates in WGS84 Projection.
   */
  geometryToWGS84(geometry, type) {
    const result = [];
    //store the outer ring
    let stringCoords =
      geometry[type]["gml:exterior"]["gml:LinearRing"]["gml:posList"];
    result.push(this.transfromStringCoords(stringCoords));

    // if there are interior rings
    if (geometry[type].hasOwnProperty("gml:interior")) {
      //many interior geometries
      if (geometry[type]["gml:interior"] instanceof Array) {
        geometry[type]["gml:interior"].forEach(interiorRing => {
          result.push(
            this.transfromStringCoords(
              interiorRing["gml:LinearRing"]["gml:posList"]
            )
          );
        });
      } else {
        //only 1 interior geometry
        let stringCoords =
          geometry[type]["gml:interior"]["gml:LinearRing"]["gml:posList"];
        result.push(this.transfromStringCoords(stringCoords));
      }
    }
    return result;
  }

  /**
   * @description Transforms a string with EPSG:3857 coordinates into a latLng Array.
   * @param {string} stringCoords - string with EPSG:3857 Coordinates divided by blankspace.
   * @return {array} result - Array of Array of latLng pairs.
   */
  transfromStringCoords(stringCoords) {
    let result = [];
    let ArrayCoords = stringCoords.split(" ");
    ArrayCoords.forEach((element, index) => {
      if (index % 2 === 0) {
        let latLng = [
          transform.YToWGS84(element),
          transform.XToWGS84(ArrayCoords[index + 1])
        ];
        result.push(latLng);
      }
    });
    return result;
  }

  /**
   * @description Get the Legend Text from the otherLegend Object/Array.
   * @param {Array or Object} other Legend - Object or Array of Legends.
   * @param {integer} index - if otherLegend is an Array, this is the index for the Object to return the Legend Text.
   * @return {string} - The Legend Text.
   */
  parseLegendText(element) {
    const otherLegend = element.Map.OtherLegend;
    const typeCode = element.TypeCode;
    if (this.isBundRestriction(element)) {
      return element.Information.LocalisedText.Text;
    }
    let result = "";
    if (typeof otherLegend === "undefined") {
      result = "keine Legende verfügbar";
      return result;
    }

    if (typeof typeCode !== "undefined") {
      if (otherLegend.constructor === Array) {
        otherLegend.forEach(legendElement => {
          if (legendElement.TypeCode === typeCode) {
            result = legendElement.LegendText.LocalisedText.Text;
          }
        });
        return result;
      }
    }

    if (otherLegend.constructor === Object) {
      if (otherLegend.hasOwnProperty("LegendText")) {
        return otherLegend.LegendText.LocalisedText.Text;
      }
    }
  }

  /**
   * @description Get the proper length for line restrictions
   * @param {object} restriction - The restriction Object.
   * @return {number} the propper formatted length.
   */
  getLength(restriction) {
    if (restriction.hasOwnProperty("LengthShare")) {
      return parseInt(restriction.LengthShare, 10);
    } else {
      return 0;
    }
  }

  /**
   * @description Get the point count for count for a restriction
   * @param {object} restriction - The restriction Object.
   * @return {number} the number of points.
   */
  getPoints(restriction) {
    if (restriction.hasOwnProperty("NrOfPoints")) {
      return parseInt(restriction.NrOfPoints, 10);
    } else {
      return 0;
    }
  }

  /**
   * @description Get the proper area for the restriction
   * @param {number} parcelArea - The area of the whole parcel.
   * @param {number} restrictionArea - The area of the restriction.
   * @return {number} the propper formated area.
   */
  getMaxArea(parcelArea, restrictionArea) {
    let result = 0;
    if (isNaN(restrictionArea) || restrictionArea === "0") {
      return result;
    }
    if (parseInt(parcelArea, 10) < parseInt(restrictionArea, 10)) {
      return parseInt(parcelArea, 10);
    } else {
      return parseInt(restrictionArea, 10);
    }
  }

  /**
   * @description Parse the legal provisions (Rechtsvorschriften) into an array.
   * @param {Object} restrictionObject - The restriction Object to get the legal provisions from.
   * @return {array} result - Array with provision Objects containing name, url and image.
   */
  parseLegalProvisions(restrictionObject) {
    let result = {
      gesetze: [],
      vorschriften: []
    };
    //if no "Rechtsvorschriften" available
    if (!restrictionObject.hasOwnProperty("LegalProvisions")) {
      return result;
    }
    if (restrictionObject.LegalProvisions.constructor === Array) {
      //array of legal provisions;
      restrictionObject.LegalProvisions.forEach(provision => {
        let type =
          provision["DocumentType"] === "LegalProvision"
            ? "vorschriften"
            : "gesetze";
        let legalProvisionName = provision.Title.LocalisedText.Text;
        let legalProvisionUrl = provision.TextAtWeb.LocalisedText.Text;
        //if no name is set for the link, take the url.
        if (!legalProvisionName) {
          legalProvisionName = legalProvisionUrl;
        }
        let image = provision.extensions.DocumentExtension["Icon"];
        let level = provision.extensions.DocumentExtension["Level"];
        let number = "-";
        if (provision.OfficialNumber) {
          number = provision.OfficialNumber;
        }
        //add the provision to the correct key in the result
        result[type].push({
          name: legalProvisionName,
          url: legalProvisionUrl,
          image: image,
          level: level,
          number: number
        });
      });
      //sort gesetzte by level. federal must come before cantonal
      result.gesetze.sort(this.sortLegalRestrictions);
    } else {
      //object of legal provision
      let type =
        restrictionObject.LegalProvisions["DocumentType"] === "LegalProvision"
          ? "vorschriften"
          : "gesetze";
      let legalProvisionName =
        restrictionObject.LegalProvisions.Title.LocalisedText.Text;
      let legalProvisionUrl =
        restrictionObject.LegalProvisions.TextAtWeb.LocalisedText.Text;
      //if no name is set for the link, take the url.
      if (!legalProvisionName) {
        legalProvisionName = legalProvisionUrl;
      }
      let image =
        restrictionObject.LegalProvisions.extensions.DocumentExtension["Icon"];
      let level =
        restrictionObject.LegalProvisions.extensions.DocumentExtension["Level"];
      result[type].push({
        name: legalProvisionName,
        url: legalProvisionUrl,
        image: image,
        level: level
      });
    }
    return result;
  }

  isBundRestriction(restriction) {
    return restriction["ResponsibleOffice"]["Name"]["LocalisedText"][
      "Text"
    ].indexOf("Bundesamt")
      ? false
      : true;
  }

  /**
   * Check if a result object with the same legal provision is already available.
   * Checks legend Text image and url.
   * @param {Object} restrictions - The already available restrictions.
   * @param {Object} newRestriction - The new restriction object.
   * @return {Object} result The restriction Object that is equal or false.
   */
  checkRestrictionEquality(restrictions, newRestriction) {
    let result = false;
    restrictions.forEach(restriction => {
      let availableLegalProvision = JSON.stringify(
        restriction.properties.legalProvisions
      );
      let newLegalProvision = JSON.stringify(
        newRestriction.properties.legalProvisions
      );
      let availableLegendText = restriction.properties.legendText;
      let newLegendText = newRestriction.properties.legendText;
      if (
        availableLegendText === newLegendText &&
        availableLegalProvision === newLegalProvision
      ) {
        result = restriction;
      }
    });
    return result;
  }

  /**
   * @description Sort the leagalRestrictions by level.
   * federal must come before cantonal
   * @param {string} a - The first level.
   * @return {string} b - The second level to compare to the first one.
   */
  sortLegalRestrictions(a, b) {
    if (a.level === "federal" && b.level === "canton") {
      return -1;
    }
    if (a.level === "canton" && b.level === "federal") {
      return 1;
    }
    return 0;
  }
}

export default OerebParser;
