import React from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import ModalContainer from "../modal/ModalContainer";
import urlUtil from "../utils/urlUtil";
import Oereb from "./Oereb";
import Auflage from "./Auflage";
import "../semantic/dist/semantic.min.css";
import "../map/Map.css";

const App = props => {
  urlUtil.init(props.history, props.location.search);
  return (
    <div>
      <ModalContainer />
      <Switch>
        <Route exact path="/auflage" component={Auflage} />
        {/*Default Route */}
        <Route component={Oereb} />
      </Switch>
    </div>
  );
};

export default App;

App.propTypes = {
  setApoState: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object
};
