import L from "leaflet";
import swissimageImage from "../assets/img/swissimage.jpeg";
import avImage from "../assets/img/av.jpeg";
import liegenschaftenImage from "../assets/img/liegenschaften.jpeg";
import urlUtil from "./urlUtil";

/**
 * custom leaflet basemap switcher logic.
 */
L.Control.BasemapSwitch = L.Control.extend({
  initialize: function(options) {
    /* initialize is Leaflet specific and can be used like a construcor */
    this.activeBasemap = options.activeBasemap;
    this.apo = options.apo;
  },

  onAdd: function(map) {
    /**
     * basemap service definitions
     * As default the swisstopo orthofoto is added to the map
     */
    const swisstopoUrl =
      "https://wmts20.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/3857/{z}/{x}/{y}.jpeg";
    const swissimageWMTS = L.tileLayer(swisstopoUrl, {
      layers: "ch.swisstopo.swissimage.noscale",
      zIndex: 0,
      maxNativeZoom: 18,
      maxZoom: 21,
      attribution: "&copy; Basemap: Swisstopo"
    });

    const pkBasemap = L.tileLayer.wms(
      "https://{s}.basemaps.lisag.ch/gwc/service/wms",
      {
        layers: this.apo
          ? "geour_basemap:basemap_apo_sw"
          : "geour_basemap:basemap_oereb_sw",
        format: "image/jpeg",
        maxZoom: 21,
        zIndex: 0,
        attribution: "&copy; Basemap: Swisstopo & Lisag AG"
      }
    );
    const liegBasemap = L.tileLayer.wms(
      "https://{s}.basemaps.lisag.ch/gwc/service/wms",
      {
        layers: "geour_basemap:liegenschaften_basemap_oereb",
        format: "image/jpeg",
        maxZoom: 21,
        zIndex: 0,
        attribution: "&copy; Basemap: Lisag AG"
      }
    );
    let activeImage = null;
    switch (this.activeBasemap) {
      case "Luftbild":
        swissimageWMTS.addTo(map);
        activeImage = swissimageImage;
        break;
      case "AV":
        pkBasemap.addTo(map);
        activeImage = avImage;
        break;
      case "Parzellen":
        liegBasemap.addTo(map);
        activeImage = liegenschaftenImage;
        break;
      default:
        swissimageWMTS.addTo(map);
        activeImage = swissimageImage;
    }

    let basemapControl = L.DomUtil.create("div");
    basemapControl.style.display = "flex";
    basemapControl.style.flexDirection = "column";

    // switch button
    let switchButton = createBasemap("Basiskarte", activeImage, null, false);
    L.DomEvent.on(switchButton.domNode, "click", ev => {
      ev.stopPropagation(); // prevent zooming when clicking on the basemap switch.
      switchButton.domNode.style.display = "none";
      window.TweenLite.to(basemapsContainer, 0.25, {
        height: "225px"
      });
    });
    basemapControl.appendChild(switchButton.domNode);

    // div that holds all the basemaps
    let basemapsContainer = L.DomUtil.create("div");
    basemapsContainer.style.display = "flex";
    basemapsContainer.style.height = "0";
    basemapsContainer.style.overflow = "hidden";
    basemapsContainer.style.flexDirection = "column";

    const basemaps = [
      createBasemap(
        "Luftbild",
        swissimageImage,
        swissimageWMTS,
        this.activeBasemap === "Luftbild" ? true : false
      ),
      createBasemap(
        "AV",
        avImage,
        pkBasemap,
        this.activeBasemap === "AV" ? true : false
      ),
      createBasemap(
        "Parzellen",
        liegenschaftenImage,
        liegBasemap,
        this.activeBasemap === "Parzellen" ? true : false
      )
    ];
    basemaps.forEach(basemap => {
      L.DomEvent.on(basemap.domNode, "click", ev => {
        ev.stopPropagation();
        if (!basemap.active) {
          removeBasemap(map, basemaps);
          addBasemap(map, basemap);
          this.activeBasemap = basemap.name;
          //change basemap url param
          const urlParams = urlUtil.getCurrentParams();
          urlParams.basemap = basemap.name;
          urlUtil.updateSearchParams(urlParams);
          // check if the click was on the label
          // we have to get the background from the parentElement then.
          const bgImage = ev.target.classList.contains("basemap-label")
            ? ev.target.parentElement.style.background
            : ev.target.style.background;
          switchButton.domNode.style.background = bgImage;
        }
        switchButton.domNode.style.display = "block";
        window.TweenLite.to(basemapsContainer, 0.25, {
          height: "0"
        });
      });
      basemapsContainer.appendChild(basemap.domNode);
    });
    basemapControl.appendChild(basemapsContainer);
    return basemapControl;
  },

  onRemove: map => {
    console.info("BasemapSwitch Removed");
  }
});

/**
 * constructor function
 * @param {object} custom options like placement. { position: "bottomright" }
 * @return {Lefleat.Control} custom basemap control.
 */
L.control.basemapswitch = opts => new L.Control.BasemapSwitch(opts);

/**
 * function that returns an instance of the custom basemap switcher.
 * the return value of this function can directly be added to a
 * leaflet map instance.
 * @param {string} position the position of the control on the map
 */
export const createBasemapSwitch = ({
  position = "topright",
  activeBasemap = "Luftbild",
  apo = false
} = {}) => L.control.basemapswitch({ position, activeBasemap, apo });

/**
 * @description creates a basemap image with a title.
 * @param {string} name name of the basemap.
 * @param {image} image background image.
 * @param {boolean} active state/visibility of the basemap.
 * @return {object} object with metadata and a domNode as properties.
 */
const createBasemap = (name, image, service, active) => {
  let basemapContainer = L.DomUtil.create("div");
  basemapContainer.style.width = "68px";
  basemapContainer.style.minHeight = "68px";
  active === true
    ? (basemapContainer.style.border = "3px solid red")
    : (basemapContainer.style.border = "3px solid #333");
  basemapContainer.style.borderRadius = "5px";
  basemapContainer.style.background = `url('${image}')`;
  basemapContainer.style.cursor = "pointer";
  //the basemap in the middle needs margin
  // this only works if there are 3 basemaps ;-)
  if (name === "AV") {
    basemapContainer.style.margin = "10px 0";
  }
  let label = L.DomUtil.create("div");
  label.classList.add("basemap-label");
  label.style.background = "rgba(0,0,0,0.7)";
  label.style.color = "white";
  label.style.textAlign = "center";
  label.innerHTML = name;
  basemapContainer.appendChild(label);

  return {
    domNode: basemapContainer,
    name: name,
    active: active,
    service: service
  };
};

/**
 * @description remove the active basemap layer and removes
 * the red border from the active basemap.
 * @param {object} map the map instance.
 * @param {array} basemaps all the available basemaps.
 */
const removeBasemap = (map, basemaps) => {
  basemaps.forEach(basemap => {
    if (basemap.active) {
      basemap.active = false;
      basemap.domNode.style.border = "3px solid #333";
      map.removeLayer(basemap.service);
    }
  });
};

/**
 * @description adds a new basemap and a red border to the
 * corresponding image.
 * @param {object} map the map instance.
 * @param {object} basemap the basemap instace to add.
 */
const addBasemap = (map, basemap) => {
  basemap.active = true;
  basemap.service.addTo(map);
  basemap.service.bringToBack();
  basemap.domNode.style.border = "3px solid red";
};
