import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toggleSidebar } from "../sidebar/sidebarActions";
import layers from "../assets/img/layers.svg";
import "./Sidebar.css";

const SidebarToggle = props => {
  const { sidebarVisible, toggleSidebar } = props;
  return (
    <div
      className="sidebar-toggle"
      onClick={() => showSidebar(sidebarVisible, toggleSidebar)}
    >
      <img
        data-testid="layer-image"
        src={layers}
        className="layer-image"
        alt="layer"
      />
    </div>
  );
};

/**
 * show/hide the sidebar with layers and results.
 * @param {boolean} sidebarVisible current visibility state of the sidebar.
 * @param {function} toggleSidebar dispatch an action to toggle the state.
 * @return {boolean} the oposite of "sidbarVisible" param.
 */
const showSidebar = (sidebarVisible, toggleSidebar) => {
  toggleSidebar(sidebarVisible);
  return !sidebarVisible;
};

const mapStateToProps = ({ sidebarVisible }) => ({
  sidebarVisible
});

const mapDispatchToProps = dispatch => ({
  toggleSidebar: newValue => dispatch(toggleSidebar(newValue))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarToggle);

SidebarToggle.propTypes = {
  sidebarVisible: PropTypes.bool,
  toggleSidebar: PropTypes.func
};
