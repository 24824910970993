import React from "react";
import Header from "../header/Header";
import AuflageContainer from "../auflage/AuflageContainer";

const Auflage = () => {
  return (
    <div>
      <Header apo={true} />
      <AuflageContainer />
    </div>
  );
};

export default Auflage;
