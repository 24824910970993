export const GET_GROUPS = "GET_GROUPS";
export const UPDATE_GROUP = "UPDATE_GROUP";

export const getGroups = () => ({
  type: GET_GROUPS
});

export const updateGroup = (group, status = "toggleGroup") => ({
  type: UPDATE_GROUP,
  group,
  status
});
