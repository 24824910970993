import React from "react";
import { connect } from "react-redux";
import { setApo, setApoPolygons } from "../apo/apoActions";
import { InstantSearch, Configure, Index } from "react-instantsearch-dom";
import AutoComplete from "./AutoComplete";
import StateSwitch from "./StateSwitch";
import urlUtil from "../utils/urlUtil";
import "./Search.css";
import PropTypes from "prop-types";

class ApoSearchContainer extends React.Component {
  render() {
    const { device, setApoState } = this.props;
    return (
      <div data-testid="search-bar" className="search apo-search">
        <InstantSearch
          appId="EZA18O6PU6"
          apiKey="25369648380de086a5b522a94c0de9c5"
          indexName="Gemeinden"
        >
          <AutoComplete withPopup={false} />

          <Configure hitsPerPage={20} />
          <Index indexName="Gemeinden" />
          <Index indexName="Liegenschaften" />
          <Index indexName="Selbstrechte" />
          <Index indexName="Projektierte Liegenschaften" />
          <Index indexName="Adressen" />
          <Index indexName="Flurnamen" />
          <Index indexName="Projektperimeter" />
        </InstantSearch>
        <StateSwitch apo={true} switchApoState={setApoState} device={device} />
      </div>
    );
  }
}
const mapStateToProps = ({ device }) => ({
  device
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setApoState: val => {
    const params = urlUtil.getCurrentParams();
    delete params.perimeter;
    urlUtil.updateSearchParams(params);
    urlUtil.toOereb();
    dispatch(setApoPolygons(null));
    dispatch(setApo(val));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApoSearchContainer);
ApoSearchContainer.propTypes = {
  device: PropTypes.string.isRequired,
  setApoState: PropTypes.func.isRequired
};
