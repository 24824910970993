import { SET_DEVICE } from "./deviceActions";
const initialState = () => {
  const deviceWidth = window.innerWidth;
  return getDevice(deviceWidth);
};

const getDevice = width => {
  if (width <= 480) {
    return "extraSmall";
  }
  if (width <= 768) {
    return "small";
  }
  if (width <= 992) {
    return "medium";
  }
  if (width < 1200) {
    return "large";
  }
  if (width >= 1200) {
    return "desktop";
  }
};
const device = (state = initialState(), action) => {
  const { type } = action;
  switch (type) {
    case SET_DEVICE:
      return getDevice(action.width);
    default:
      return state;
  }
};
export default device;
