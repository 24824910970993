import React from "react";
import { InstantSearch, Configure, Index } from "react-instantsearch-dom";
import AutoComplete from "./AutoComplete";
import "./Search.css";

const SearchContainer = () => {
  return (
    <div data-testid="search-bar" className="search">
      <InstantSearch
        appId="EZA18O6PU6"
        apiKey="25369648380de086a5b522a94c0de9c5"
        indexName="Gemeinden"
      >
        <AutoComplete withPopup={true} />

        <Configure hitsPerPage={20} />
        <Index indexName="Gemeinden" />
        <Index indexName="Liegenschaften" />
        <Index indexName="Selbstrechte" />
        <Index indexName="Projektierte Liegenschaften" />
        <Index indexName="Adressen" />
        <Index indexName="Flurnamen" />
      </InstantSearch>
    </div>
  );
};

export default SearchContainer;
