import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { hideModal } from "./modalActions";
import OrderModal from "./OrderModal";
import MetadataModal from "./MetadataModal";

const ModalContainer = ({ modal, closeModal }) => {
  let activeModal = null;
  switch (modal.modalType) {
    case "layerMetadata":
      activeModal = <MetadataModal modal={modal} closeModal={closeModal} />;
      break;
    case "orderOereb":
      activeModal = <OrderModal modal={modal} closeModal={closeModal} />;
      break;
    case "orderFail":
      activeModal = <OrderModal modal={modal} closeModal={closeModal} />;
      break;
    default:
      return activeModal;
  }
  return activeModal;
};

const mapStateToProps = ({ modal }) => ({
  modal
});
const mapDispatchToProps = dispatch => ({
  closeModal: () => {
    dispatch(hideModal());
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalContainer);

ModalContainer.propTypes = {
  modal: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired
};
