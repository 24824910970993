import React, { Suspense } from "react";
import Header from "../header/Header";
import SearchContainer from "../search/SearchContainer";
import SidebarToggle from "../sidebar/SidebarToggle";
const OerebMap = React.lazy(() => import("../map/OerebMap"));
const SidebarContainer = React.lazy(() =>
  import("../sidebar/SidebarContainer")
);

const Oereb = () => {
  return (
    <div>
      <Header apo={false} />
      <SearchContainer />
      <SidebarToggle apo={false} />
      <Suspense fallback={<div className="map-placeholder" />}>
        <OerebMap />
      </Suspense>
      <Suspense fallback={<span />}>
        <SidebarContainer apo={false} />
      </Suspense>
    </div>
  );
};

export default Oereb;
