import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app/App";
import { setDevice } from "./app/deviceActions";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import mainReducer from "./app/index_reducer";
import registerServiceWorker from "./registerServiceWorker";

const store = createStore(
  mainReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

window.addEventListener("resize", () => {
  store.dispatch(setDevice(window.innerWidth));
});

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Route path="/" component={App} />
    </Router>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();
