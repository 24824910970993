import L from "leaflet";
import {
  getLisagLayerMetadata,
  getBundLayerMetadata
} from "../utils/layerUtil";

/**
 * custom leaflet basemap switcher logic.
 */
L.Control.LegendButton = L.Control.extend({
  initialize: function(options) {
    /* initialize is Leaflet specific and can be used like a construcor */
    this.layers = options.layers;
    this.displayMetadata = options.displayMetadata;
  },

  onAdd: function(map) {
    const legendbutton = L.DomUtil.create("div");
    legendbutton.style.boxShadow = "0 1px 4px rgba(0, 0, 0, 0.7)";
    legendbutton.style.width = "40px";
    legendbutton.style.height = "40px";
    legendbutton.style.backgroundColor = "white";
    legendbutton.style.border = "4px solid #333";
    legendbutton.style.marginRight = "20px";
    legendbutton.style.borderRadius = "20px";
    legendbutton.style.display = "flex";
    legendbutton.style.alignItems = "center";
    legendbutton.style.justifyContent = "center";
    legendbutton.style.cursor = "pointer";

    const info = L.DomUtil.create("span");
    info.style.fontSize = "20px";
    info.style.fontWeight = "bold";
    info.innerHTML = "i";

    legendbutton.appendChild(info);

    L.DomEvent.on(legendbutton, "click", ev => {
      ev.stopPropagation(); // prevent zooming when clicking on the basemap switch.
      const promises = [];
      this.displayMetadata(promises);
      this.layers.forEach(layer => {
        if (layer.layerBund) {
          promises.push(getBundLayerMetadata(layer));
        } else {
          promises.push(getLisagLayerMetadata(layer));
        }
      });
      Promise.all(promises)
        .then(values => this.displayMetadata(values))
        .catch(error =>
          alert(
            `🤔 Es gabe einen Fehler beim Laden der Metadaten. Nachricht:${error}`
          )
        );
    });
    return legendbutton;
  },

  onRemove: map => {
    console.info("LegendButton Removed");
  }
});

/**
 * constructor function
 * @param {object} custom options like placement. { position: "bottomright" }
 * @return {Lefleat.Control} custom basemap control.
 */
L.control.legendbutton = opts => new L.Control.LegendButton(opts);

/**
 * function that returns an instance of the custom basemap switcher.
 * the return value of this function can directly be added to a
 * leaflet map instance.
 * @param {string} position the position of the control on the map
 */
export const createLegendButton = ({
  position = "topright",
  layers = "",
  displayMetadata = null
} = {}) => L.control.legendbutton({ position, layers, displayMetadata });
