import { combineReducers } from "redux";
import device from "./deviceReducer";
import { sidebarVisible, sidebarTab } from "../sidebar/sidebarReducer";
import groupLayers from "../toc/layerGroupReducer";
import layers from "../toc/layerReducer";
import modal from "../modal/modalReducer";
import { oerebResult, currentParcel } from "../oerebResult/oerebReducer";
import { apo, apoPolygons } from "../apo/apoReducer";
export default combineReducers({
  device,
  sidebarVisible,
  sidebarTab,
  groupLayers,
  layers,
  modal,
  oerebResult,
  currentParcel,
  apo,
  apoPolygons
});
