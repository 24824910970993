import { GET_LAYERS, UPDATE_LAYER } from "./layerActions";

const layers = (state = layerList, action) => {
  switch (action.type) {
    case GET_LAYERS:
      return state;
    case UPDATE_LAYER:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.layer.id]: action.layer
        }
      };
    default:
      return state;
  }
};

export default layers;

export const layerList = {
  byId: {
    "Kantonale Planungszonen": {
      data: false,
      layerBund: false,
      position: 14,
      id: "Kantonale Planungszonen",
      geoserverNamespace: "",
      geoserverLayerName: "",
      visibility: false,
      opacity: 100,
      noDataText:
        "Zurzeit hat der Kanton keine Planungszone nach Art. 56 ff. PBG erlassen."
    },
    "Gemeindliche Planungszonen": {
      data: true,
      layerBund: false,
      position: 13,
      id: "Gemeindliche Planungszonen",
      geoserverNamespace: "raumplanung",
      geoserverLayerName: "ch76b_gemeindliche_planungszonen",
      visibility: false,
      opacity: 100
    },
    "Kantonale Nutzungsplanung": {
      data: false,
      layerBund: false,
      position: 12,
      id: "Kantonale Nutzungsplanung",
      geoserverNamespace: "raumplanung",
      geoserverLayerName: "ur14_kantonale_nutzungsplanung",
      visibility: false,
      opacity: 100,
      noDataText:
        "Zurzeit hat der Kanton keine Nutzungsplanung gemäss Art. 11 PBG erlassen."
    },
    "Kantonale Baulinien": {
      data: false,
      layerBund: false,
      position: 15,
      id: "Kantonale Baulinien",
      geoserverNamespace: "raumplanung",
      geoserverLayerName: "ur15_kantonale_baulinien",
      visibility: false,
      opacity: 100,
      noDataText:
        "Zurzeit hat der Kanton keine Baulinien gemäss Art. 14 PBG erlassen."
    },
    Grundnutzung: {
      data: true,
      layerBund: false,
      position: 1,
      id: "Grundnutzung",
      geoserverNamespace: "raumplanung",
      geoserverLayerName: "grundnutzung_oereb",
      visibility: false,
      opacity: 70
    },
    Gefahrenzonen: {
      data: true,
      layerBund: false,
      position: 2,
      id: "Gefahrenzonen",
      geoserverNamespace: "raumplanung",
      geoserverLayerName: "gefahrenzonen_oereb",
      visibility: false,
      opacity: 100
    },
    "Flächenbezogene Festlegungen": {
      data: true,
      layerBund: false,
      position: 3,
      id: "Flächenbezogene Festlegungen",
      geoserverNamespace: "raumplanung",
      geoserverLayerName: "flaechenbezogene_festlegungen_oereb",
      visibility: false,
      opacity: 100
    },
    "Linienbezogene Festlegungen": {
      data: true,
      layerBund: false,
      position: 4,
      id: "Linienbezogene Festlegungen",
      geoserverNamespace: "raumplanung",
      geoserverLayerName: "linienbezogene_festlegungen_oereb",
      visibility: false,
      opacity: 100
    },
    "Punktbezogene Festlegungen": {
      data: true,
      layerBund: false,
      position: 5,
      id: "Punktbezogene Festlegungen",
      geoserverNamespace: "raumplanung",
      geoserverLayerName: "punktbezogene_festlegungen_oereb",
      visibility: false,
      opacity: 100
    },
    Quartierpläne: {
      data: true,
      layerBund: false,
      position: 6,
      id: "Quartierpläne",
      geoserverNamespace: "raumplanung",
      geoserverLayerName: "ur17_quartierplaene",
      visibility: false,
      opacity: 100
    },
    Quartiergestaltungspläne: {
      data: true,
      layerBund: false,
      position: 7,
      id: "Quartiergestaltungspläne",
      geoserverNamespace: "raumplanung",
      geoserverLayerName: "ur18_quartiergestaltungsplaene",
      visibility: false,
      opacity: 100
    },
    "Gemeindliche Baulinien": {
      data: false,
      layerBund: false,
      position: 8,
      id: "Gemeindliche Baulinien",
      geoserverNamespace: "raumplanung",
      geoserverLayerName: "ur61_gemeindliche_baulinien",
      visibility: false,
      opacity: 100,
      noDataText:
        "Zurzeit haben die Gemeinden keine Baulinien im Rahmen der Sondernutzungsplanung erlassen."
    },
    "Lärmempfindlichkeitsstufen (in Nutzungszonen)": {
      data: true,
      layerBund: false,
      position: 9,
      id: "Lärmempfindlichkeitsstufen (in Nutzungszonen)",
      geoserverNamespace: "raumplanung",
      geoserverLayerName: "gbd_laermempfindlichkeit_zonenflaeche",
      visibility: false,
      opacity: 100
    },
    "Statische Waldgrenzen": {
      data: true,
      layerBund: false,
      position: 10,
      id: "Statische Waldgrenzen",
      geoserverNamespace: "raumplanung",
      geoserverLayerName: "gbd_waldgrenze_linie",
      visibility: false,
      opacity: 100
    },
    Waldabstandslinien: {
      data: true,
      layerBund: false,
      position: 11,
      id: "Waldabstandslinien",
      geoserverNamespace: "raumplanung",
      geoserverLayerName: "gbd_waldabstand_linie",
      visibility: false,
      opacity: 100
    },
    Grundwasserschutzzonen: {
      data: true,
      layerBund: false,
      position: 16,
      id: "Grundwasserschutzzonen",
      geoserverNamespace: "raumplanung",
      geoserverLayerName: "grundwasserschutzzone_overview",
      visibility: false,
      opacity: 100
    },
    Grundwasserschutzareale: {
      data: true,
      layerBund: false,
      position: 17,
      id: "Grundwasserschutzareale",
      geoserverNamespace: "raumplanung",
      geoserverLayerName: "grundwasserschutzareal_overview",
      visibility: false,
      opacity: 100
    },
    "Baulinien Nationalstrassen": {
      data: true,
      layerBund: true,
      position: 18,
      id: "Baulinien Nationalstrassen",
      geoserverNamespace: "",
      geoserverLayerName: "ch.astra.baulinien-nationalstrassen.oereb",
      visibility: false,
      opacity: 100
    },
    "Projektierungszonen Nationalstrassen": {
      data: false,
      layerBund: true,
      position: 19,
      id: "Projektierungszonen Nationalstrassen",
      geoserverNamespace: "",
      geoserverLayerName: "ch.astra.projektierungszonen-nationalstrassen.oereb",
      visibility: false,
      opacity: 100,
      noDataText:
        "Zurzeit hat der Bund im Kantonsgebiet keine Projektierungszonen Nationalstrassen erlassen."
    },
    "Baulinien Eisenbahnanlagen": {
      data: false,
      layerBund: true,
      position: 20,
      id: "Baulinien Eisenbahnanlagen",
      geoserverNamespace: "",
      geoserverLayerName: "ch.bav.baulinien-eisenbahnanlagen.oereb",
      visibility: false,
      opacity: 100,
      noDataText:
        "Zurzeit hat der Bund im Kantonsgebiet keine Baulinien Eisenbahnanlagen erlassen."
    },
    "Projektierungszonen Eisenbahnanlagen": {
      data: false,
      layerBund: true,
      position: 21,
      id: "Projektierungszonen Eisenbahnanlagen",
      geoserverNamespace: "",
      geoserverLayerName: "ch.bav.projektierungszonen-eisenbahnanlagen.oereb",
      visibility: false,
      opacity: 100,
      noDataText:
        "Zurzeit hat der Bund im Kantonsgebiet keine Projektierungszonen Eisenbahnanlagen erlassen."
    },
    "Baulinien Flughafenanlagen": {
      data: false,
      layerBund: true,
      position: 22,
      id: "Baulinien Flughafenanlagen",
      geoserverNamespace: "",
      geoserverLayerName: "ch.bazl.baulinien-flughafenanlagen.oereb",
      visibility: false,
      opacity: 100,
      noDataText:
        "Zurzeit hat der Bund im Kantonsgebiet keine Baulinien Flughafenanlagen erlassen."
    },
    "Projektierungszonen Flughafenanlagen": {
      data: false,
      layerBund: true,
      position: 23,
      id: "Projektierungszonen Flughafenanlagen",
      geoserverNamespace: "",
      geoserverLayerName: "ch.bazl.projektierungszonen-flughafenanlagen.oereb",
      visibility: false,
      opacity: 100,
      noDataText:
        "Zurzeit hat der Bund im Kantonsgebiet keine Projektierungszonen Flughafenanlagen erlassen."
    },
    Sicherheitszonenplan: {
      data: false,
      layerBund: true,
      position: 24,
      id: "Sicherheitszonenplan",
      geoserverNamespace: "",
      geoserverLayerName: "ch.bazl.sicherheitszonenplan.oereb",
      visibility: false,
      opacity: 100,
      noDataText:
        "Zurzeit hat der Bund im Kantonsgebiet keinen Sicherheitszonenplan erlassen."
    },
    KbS: {
      data: true,
      layerBund: false,
      position: 25,
      id: "KbS",
      geoserverNamespace: "umwelt",
      geoserverLayerName: "ch116_bst_belasteter_standort_fla",
      visibility: false,
      opacity: 100
    },
    "KbS Bund - Militär": {
      data: false,
      layerBund: true,
      position: 26,
      id: "KbS Bund - Militär",
      geoserverNamespace: "",
      geoserverLayerName: "",
      visibility: false,
      opacity: 100,
      noDataText:
        "Zurzeit liegen die Daten des Bundes zum KbS im Bereich Militär nicht vor."
    },
    "KbS Bund - zivile Flugplätze": {
      data: false,
      layerBund: true,
      position: 27,
      id: "KbS Bund - zivile Flugplätze",
      geoserverNamespace: "",
      geoserverLayerName:
        "ch.bazl.kataster-belasteter-standorte-zivilflugplaetze.oereb",
      visibility: false,
      opacity: 100,
      noDataText:
        "Zurzeit hat der Bund im Kantonsgebiet keinen Eintrag im KbS im Bereich zivile Flugplätze vorgenommen."
    },
    "KbS Bund - öffentlicher Verkehr": {
      data: true,
      layerBund: true,
      position: 28,
      id: "KbS Bund - öffentlicher Verkehr",
      geoserverNamespace: "",
      geoserverLayerName: "ch.bav.kataster-belasteter-standorte-oev.oereb",
      visibility: false,
      opacity: 100
    },
    "Grundnutzung proj.": {
      data: true,
      layerBund: false,
      position: 29,
      id: "Grundnutzung proj.",
      geoserverNamespace: "raumplanung",
      geoserverLayerName: "prj_gbd_grundnutzung_zonenflaeche",
      visibility: false,
      opacity: 70
    },
    "Gefahrenzonen proj.": {
      data: true,
      layerBund: false,
      position: 30,
      id: "Gefahrenzonen proj.",
      geoserverNamespace: "raumplanung",
      geoserverLayerName: "prj_gbd_ueberlagernde_festlegung_gefahren",
      visibility: false,
      opacity: 100
    },
    "Flächenbezogene Festlegungen proj.": {
      data: true,
      layerBund: false,
      position: 31,
      id: "Flächenbezogene Festlegungen proj.",
      geoserverNamespace: "raumplanung",
      geoserverLayerName: "prj_gbd_ueberlagernde_festlegung",
      visibility: false,
      opacity: 100
    },
    "Linienbezogene Festlegungen proj.": {
      data: true,
      layerBund: false,
      position: 32,
      id: "Linienbezogene Festlegungen proj.",
      geoserverNamespace: "raumplanung",
      geoserverLayerName: "prj_gbd_linienbezogene_festlegung",
      visibility: false,
      opacity: 100
    },
    "Punktbezogene Festlegungen proj.": {
      data: true,
      layerBund: false,
      position: 33,
      id: "Punktbezogene Festlegungen proj.",
      geoserverNamespace: "raumplanung",
      geoserverLayerName: "prj_gbd_objektbezogene_festlegung",
      visibility: false,
      opacity: 100
    },
    "Statische Waldgrenzen proj.": {
      data: true,
      layerBund: false,
      position: 34,
      id: "Statische Waldgrenzen proj.",
      geoserverNamespace: "raumplanung",
      geoserverLayerName: "prj_gbd_waldgrenze_linie",
      visibility: false,
      opacity: 100
    },
    "Waldabstandslinien proj.": {
      data: true,
      layerBund: false,
      position: 35,
      id: "Waldabstandslinien proj.",
      geoserverNamespace: "raumplanung",
      geoserverLayerName: "prj_gbd_waldabstand_linie",
      visibility: false,
      opacity: 100
    },
    "Lärmempfindlichkeitsstufen (in Nutzungszonen) proj.": {
      data: true,
      layerBund: false,
      position: 36,
      id: "Lärmempfindlichkeitsstufen (in Nutzungszonen) proj.",
      geoserverNamespace: "raumplanung",
      geoserverLayerName: "prj_gbd_laermempfindlichkeit_zonenflaeche",
      visibility: false,
      opacity: 100
    },
    "Grundwasserschutzzonen proj.": {
      data: true,
      layerBund: false,
      position: 37,
      id: "Grundwasserschutzzonen proj.",
      geoserverNamespace: "raumplanung",
      geoserverLayerName: "prj_ch131_gwszone",
      visibility: false,
      opacity: 100
    },
    // This Layer is only temporary as long as there are any Planungszonen.
    Planungszonen: {
      data: true,
      layerBund: false,
      position: 38,
      id: "Planungszonen",
      geoserverNamespace: "raumplanung",
      geoserverLayerName: "planungszonen",
      visibility: false,
      opacity: 100
    }
  },
  allIds: [
    "Kantonale Planungszonen",
    "Gemeindliche Planungszonen",
    "Kantonale Nutzungsplanung",
    "Kantonale Baulinien",
    "Grundnutzung",
    "Gefahrenzonen",
    "Flächenbezogene Festlegungen",
    "Linienbezogene Festlegungen",
    "Punktbezogene Festlegungen",
    "Quartierpläne",
    "Quartiergestaltungspläne",
    "Gemeindliche Baulinien",
    "Lärmempfindlichkeitsstufen (in Nutzungszonen)",
    "Statische Waldgrenzen",
    "Waldabstandslinien",
    "Grundwasserschutzzonen",
    "Grundwasserschutzareale",
    "Baulinien Nationalstrassen",
    "Projektierungszonen Nationalstrassen",
    "Baulinien Eisenbahnanlagen",
    "Projektierungszonen Eisenbahnanlagen",
    "Baulinien Flughafenanlagen",
    "Projektierungszonen Flughafenanlagen",
    "Sicherheitszonenplan",
    "KbS",
    "KbS Bund - Militär",
    "KbS Bund - zivile Flugplätze",
    "KbS Bund - öffentlicher Verkehr",
    "Grundnutzung proj.",
    "Gefahrenzonen proj.",
    "Flächenbezogene Festlegungen proj.",
    "Linienbezogene Festlegungen proj.",
    "Punktbezogene Festlegungen proj.",
    "Statische Waldgrenzen proj.",
    "Waldabstandslinien proj.",
    "Lärmempfindlichkeitsstufen (in Nutzungszonen) proj.",
    "Grundwasserschutzzonen proj.",
    "Planungszonen"
  ]
};
