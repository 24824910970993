const transform = {
    // Convert WGS84 LatLng to EPSG:3857 WebMercator
    toWebMercator(mercatorY_lat, mercatorX_lon) {
        if (Math.abs(mercatorX_lon) > 180 || Math.abs(mercatorY_lat) > 90) {
            return;
        }
        let num = mercatorX_lon * 0.017453292519943295;
        let x = 6378137.0 * num;
        let a = mercatorY_lat * 0.017453292519943295;
        mercatorX_lon = x;
        mercatorY_lat =
            3189068.5 * Math.log((1.0 + Math.sin(a)) / (1.0 - Math.sin(a)));
        let result = [mercatorX_lon, mercatorY_lat];
        return result;
    },

    /*@description Convert a WGS84 lat coordinate to a EPSG:3857 Y Coordinate.
    * @Param {float} lat - The WGS84 lat coordinate.
    */
    latToWebMercator(lat) {
        if (Math.abs(lat) > 90) {
            return;
        }
        let a = lat * 0.017453292519943295;
        let mercatorY =
            3189068.5 * Math.log((1.0 + Math.sin(a)) / (1.0 - Math.sin(a)));
        return mercatorY;
    },

    /*@description Convert a WGS84 lng coordinate to a EPSG:3857 X Coordinate.
    * @Param {float} lng - The WGS84 lat coordinate.
    */
    lngToWebMercator(lng) {
        if (Math.abs(lng) > 180) {
            return;
        }
        let num = lng * 0.017453292519943295;
        let mercatorX = 6378137.0 * num;
        return mercatorX;
    },

    /*
    Recursivly converts Array of EPSG:3857 Coordinates to WGS84
    This function works by reference. So values in the parameter "coordArray" are going to change!
    @param coordArray; Array; Array of geometry coordinates (x/y).
    */
    webMercatorToWGS84(coordArray) {
        for (var i = 0; i < coordArray.length; i++) {
            if (coordArray[i].length !== 2) {
                this.webMercatorToWGS84(coordArray[i]);
            } else {
                coordArray[i][0] = this.YToWGS84(coordArray[i][0]);
                coordArray[i][1] = this.XToWGS84(coordArray[i][1]);
            }
        }
    },

    //Convert EPSG:3857 Y Coordinate to WGS84 LatLng
    YToWGS84(mercatorY_lon) {
        if (Math.abs(mercatorY_lon) < 180) {
            return;
        }

        if (Math.abs(mercatorY_lon) > 20037508.3427892) {
            return;
        }
        var x = mercatorY_lon;
        var num3 = x / 6378137.0;
        var num4 = num3 * 57.295779513082323;
        var num5 = Math.floor((num4 + 180.0) / 360.0);
        var num6 = num4 - num5 * 360.0;
        mercatorY_lon = num6;
        return mercatorY_lon;
    },

    //Convert EPSG:3857 X Coordinate to WGS84 LatLng
    XToWGS84(mercatorX_lat) {
        if (Math.abs(mercatorX_lat) < 90) {
            return;
        }
        if (Math.abs(mercatorX_lat) > 20037508.3427892) {
            return;
        }
        var y = mercatorX_lat;
        var num7 =
            1.5707963267948966 -
            2.0 * Math.atan(Math.exp(-1.0 * y / 6378137.0));
        mercatorX_lat = num7 * 57.295779513082323;
        return mercatorX_lat;
    },
    // Convert LV95 E/N to WGS lat
    LV95toWGSlat(E, N) {
        // Converts militar to civil and  to unit = 1000km
        // Axiliary values (% Bern)
        var E_aux = (E - 2600000) / 1000000;
        var N_aux = (N - 1200000) / 1000000;

        // Process lat
        let lat =
            16.9023892 +
            3.238272 * N_aux -
            0.270978 * Math.pow(E_aux, 2) -
            0.002528 * Math.pow(N_aux, 2) -
            0.0447 * Math.pow(E_aux, 2) * N_aux -
            0.014 * Math.pow(N_aux, 3);

        // Unit 10000" to 1 " and converts seconds to degrees (dec)
        lat = lat * 100 / 36;

        return lat;
    },

    // Convert LV95 E/N to WGS long
    LV95toWGSlng(E, N) {
        // Converts militar to civil and  to unit = 1000km
        // Axiliary values (% Bern)
        var E_aux = (E - 2600000) / 1000000;
        var N_aux = (N - 1200000) / 1000000;

        // Process long
        let lng =
            2.6779094 +
            4.728982 * E_aux +
            0.791484 * E_aux * N_aux +
            0.1306 * E_aux * Math.pow(N_aux, 2) -
            0.0436 * Math.pow(E_aux, 3);

        // Unit 10000" to 1 " and converts seconds to degrees (dec)
        lng = lng * 100 / 36;

        return lng;
    },
    // Convert WGS lat/long ( dec) to CH y
    WGStoLV95E(lat, lng) {
        // Converts degrees dec to sex
        lat = this.DECtoSEX(lat);
        lng = this.DECtoSEX(lng);

        // Converts degrees to seconds (sex)
        lat = this.DEGtoSEC(lat);
        lng = this.DEGtoSEC(lng);

        // Axiliary values (% Bern)
        var lat_aux = (lat - 169028.66) / 10000;
        var lng_aux = (lng - 26782.5) / 10000;

        // Process Y
        const y =
            2600072.37 +
            211455.93 * lng_aux -
            10938.51 * lng_aux * lat_aux -
            0.36 * lng_aux * Math.pow(lat_aux, 2) -
            44.54 * Math.pow(lng_aux, 3);

        return y;
    },

    // Convert WGS lat/long ( dec) to CH x
    WGStoLV95N(lat, lng) {
        // Converts degrees dec to sex
        lat = this.DECtoSEX(lat);
        lng = this.DECtoSEX(lng);

        // Converts degrees to seconds (sex)
        lat = this.DEGtoSEC(lat);
        lng = this.DEGtoSEC(lng);

        // Axiliary values (% Bern)
        var lat_aux = (lat - 169028.66) / 10000;
        var lng_aux = (lng - 26782.5) / 10000;

        // Process X
        const x =
            1200147.07 +
            308807.95 * lat_aux +
            3745.25 * Math.pow(lng_aux, 2) +
            76.63 * Math.pow(lat_aux, 2) -
            194.56 * Math.pow(lng_aux, 2) * lat_aux +
            119.79 * Math.pow(lat_aux, 3);

        return x;
    },
    // Convert DEC angle to SEX DMS
    DECtoSEX(angle) {
        // Extract DMS
        var deg = parseInt(angle, 10);
        var min = parseInt((angle - deg) * 60, 10);
        var sec = ((angle - deg) * 60 - min) * 60;

        // Result in degrees sex (dd.mmss)
        return deg + min / 100 + sec / 10000;
    },

    // Convert Degrees angle to seconds
    DEGtoSEC(angle) {
        // Extract DMS
        var deg = parseInt(angle, 10);
        var min = parseInt((angle - deg) * 100, 10);
        var sec = ((angle - deg) * 100 - min) * 100;

        // Result in degrees sex (dd.mmss)
        return sec + min * 60 + deg * 3600;
    }
};

export default transform;
