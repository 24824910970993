export const GET_LAYERS = "GET_LAYERS";
export const UPDATE_LAYER = "UPDATE_LAYER";
export const GET_PROJ_LAYERS = "GET_PROJ_LAYERS";
export const UPDATE_PROJ_LAYER = "UPDATE_PROJ_LAYER";

export const getLayers = () => ({
  type: GET_LAYERS
});

export const getProjLayers = () => ({
  type: GET_PROJ_LAYERS
});

export const updateLayer = layer => ({
  type: UPDATE_LAYER,
  layer
});

export const updateProjLayer = layer => ({
  type: UPDATE_PROJ_LAYER,
  layer
});
