import L from "leaflet";
import { polygon } from "@turf/helpers";
import intersect from "@turf/intersect";
import { getAPOpolygons } from "./requestUtil";
import { maps } from "./mapStateUtil";

/**
 * style for a egrid overlay
 */
const egridStyle = {
  style: feature => ({
    color: "black",
    fillColor: "#ff0000",
    fillOpacity: 0.3,
    weight: 4
  })
};

/**
 * style for a temporary polygon
 */
const tempPolygonStyle = {
  style: feature => ({
    color: "red",
    fillColor: "yellow",
    fillOpacity: 0.7,
    weight: 3
  })
};

/**
 * style for a projektperimeter overlay
 */
const perimeterStyle = {
  style: feature => ({
    color: "#ffd700",
    fillColor: "#000000",
    fillOpacity: 0,
    weight: 6
  })
};
/**
 * style for a temporary line
 */
const tempLineStyle = {
  style: feature => ({
    color: "red",
    weight: 5
  })
};
/**
 * style for a temporary point
 */
const tempPointStyle = {
  radius: 5,
  fillColor: "yellow",
  color: "red",
  weight: 2,
  opacity: 1,
  fillOpacity: 1
};

export const showCurrentOverlay = (map, style) => {
  map.geojson.addTo(map);
  map.geojson.setStyle(feature => style);
};

export const removeAllOverlays = () => {
  maps.forEach(map => {
    if (map.geojson) {
      map.geojson.remove();
    }
  });
};

export const flyToCurrentOverlay = map => {
  map.flyToBounds(map.geojson.getBounds(), {
    animate: true,
    duration: 0.15,
    paddingTopLeft: calculateGeojsonPadding(),
    maxZoom: 19
  });
};

/**
 * creates a lefleat GeoJSON Layer from a geoserver wfs request.
 * @param {object} geojson  valid geojson object.
 * @return {object} leaflet geojson object.
 */
export const createGeojsonOverlay = ({ geojson = null, temp = false } = {}) => {
  const { type } = geojson.geometry;
  let style = null;
  let geojsonLayer = null;
  switch (type) {
    case "Point":
    case "MultiPoint":
      style = tempPointStyle;
      break;
    case "LineString":
    case "MultiLineString":
      style = tempLineStyle;
      break;
    default:
      style = temp ? tempPolygonStyle : egridStyle;
  }
  if (type.indexOf("Point") > -1) {
    geojsonLayer = L.geoJSON(geojson, {
      pointToLayer: (feature, latlng) => {
        return L.circleMarker(latlng, style);
      }
    });
  } else {
    geojsonLayer = L.geoJSON(geojson, style);
  }
  return geojsonLayer;
};

/**
 * Display a geojson overlay on a map.
 * @param {object} geojson A Geojson like received from a wfs or getFeatureInfo Request.
 * @param {object} map Leafelet map object.
 * @return {object} Promise with a leaflet geojson object.
 */
export const displayGeojson = ({
  geojson = null,
  maps = [],
  flyToBounds = false,
  removeExisting = true
} = {}) => {
  if (removeExisting) {
    removeAllOverlays();
  }
  const layer = createGeojsonOverlay({
    geojson: geojson,
    removeExisting: removeExisting
  });
  return new Promise((resolve, reject) => {
    const addGeojson = () => {
      if (maps.length === 1) {
        layer.addTo(maps[0]);
        maps[0].geojson = layer;
        resolve(layer);
      } else {
        // create a new layer for every map (one layer for many maps does not work)
        const layers = maps.map(map =>
          createGeojsonOverlay({
            geojson: geojson,
            removeExisting: removeExisting
          })
        );
        for (var i = 0; i < layers.length; i++) {
          layers[i].addTo(maps[i]);
          maps[i].geojson = layers[i];
        }
        resolve(layers[0]);
      }
    };
    if (flyToBounds) {
      maps[0].flyToBounds(layer.getBounds(), {
        animate: true,
        duration: 0.15,
        paddingTopLeft: calculateGeojsonPadding(),
        maxZoom: 19
      });
      // add the layer after zooming because of
      // strange scaling effects with thick lines.
      maps[0].once("zoomend", () => {
        addGeojson();
      });
    } else {
      addGeojson();
    }
  });
};

/**
 * creates a lefleat GeoJSON Layer for a Projektperimeter.
 * @param {object} geojson  valid geojson object.
 * @return {object} leaflet geojson object.
 */
export const createProjektperimeterOvelay = geojson => {
  return L.geoJSON(geojson, perimeterStyle);
};

/**
 * creates a lefleat GeoJSON Layer and overlay it for 3s on the map.
 * @param {object} geojson  valid geojson object.
 * @param {object} map leaflet map object.
 * @return {object} leaflet geojson object.
 */
export const createTemporaryGeojsonOverlay = (
  geojson,
  map,
  target = null,
  animation = "no"
) => {
  const animationOptions = {
    duration: 0.5
  };
  const geojsonLayer = createGeojsonOverlay({ geojson: geojson, temp: true });
  const mapBounds = map.getBounds();
  if (target) {
    target.classList.add("green");
  }
  if (animation === "fly") {
    const overlayBounds = geojsonLayer.getBounds();
    map.flyToBounds(overlayBounds, {
      ...animationOptions,
      paddingTopLeft: calculateGeojsonPadding()
    });
  }
  geojsonLayer.addTo(map);

  window.setTimeout(() => {
    if (target) {
      target.classList.remove("green");
    }
    geojsonLayer.remove();

    if (animation === "fly") {
      map.flyToBounds(mapBounds, animationOptions);
    }
  }, 4000);
  return geojsonLayer;
};

/**
 * check if a parcel intersects with a apo polygon.
 * @param {geojson} parcel A valid Geojson object of the parcel.
 * @return {object} leaflet GeoJSON object with associated Popup.
 */
export const checkAPOIntersection = parcel => {
  const result = [];
  return getAPOpolygons("suche:apo_perimeter")
    .then(response => {
      let poly = geojsonToPolygon(parcel);
      for (var i = 0; i < response.features.length; i++) {
        // this second loop is necessary in case of multipart geometries
        for (
          var j = 0;
          j < response.features[i].geometry.coordinates.length;
          j++
        ) {
          let apoPolygon = polygon(
            response.features[i].geometry.coordinates[j]
          );
          //perform the intersection
          if (intersect(apoPolygon, poly)) {
            result.push(response.features[i]);
          }
        }
      }

      return result;
    })
    .catch(error => alert(error));
};

/**
 * Calculate the left padding a geojson must have on center.
 * This is necessary if the sidebar is visible to not obscure the geojson.
 * @return {array} the topLeft padding.
 */
export const calculateGeojsonPadding = () => {
  const sidebar = document.getElementsByClassName("sidebar")[0];
  if (sidebar) {
    const sidebarVisible = sidebar.style.display === "none" ? false : true;
    if (!sidebarVisible || (sidebarVisible && window.innerWidth < 600)) {
      return [0, 150];
    }
    return [300, 150];
  }
  return [0, 0];
};

/**
 * Calculate the autoPanPaddingTopLeft a popup must have.
 * @return {array} the topLeft padding.
 */
export const calculatePopupPadding = () => {
  if (window.innerWidth < 600) {
    return [0, 0];
  }
  return [20, 20];
};

/**
 * Creates a Turf.polygon from a geojson Poly or MutliPolygon.
 * buffers points and lines in order to do so.
 * @param {object} geojson - a geojson object from a wfs request
 * @return {object} Turf polygon.
 */
const geojsonToPolygon = geojson => {
  const { type } = geojson.geometry;
  if (type === "Polygon" || type === "MultiPolygon") {
    return polygon(geojson.geometry.coordinates[0]);
  }
};
