export const SET_OEREB_RESULT = "SET_OEREB_RESULT";
export const SET_CURRENT_PARCEL = "SET_CURRENT_PARCEL";

export const setOerebResult = oerebResult => ({
  type: SET_OEREB_RESULT,
  oerebResult
});

export const setCurrentParcel = currentParcel => ({
  type: SET_CURRENT_PARCEL,
  currentParcel
});
