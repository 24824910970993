import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Dropdown } from "semantic-ui-react";
const HeaderLinks = props => {
  const { device, apo } = props;
  return (
    <div className="header-links">
      <Dropdown
        direction="left"
        text={
          device === "desktop"
            ? "Weitere Informationen zum ÖREB-Kataster"
            : "Links"
        }
        style={{ float: "right" }}
      >
        <Dropdown.Menu id="headerDropdown" style={{ zIndex: 10000 }}>
          <a
            className="header-link item"
            href="https://webgis.lisag.ch/PDF/Richtigkeitsbest%C3%A4tigungen/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Dropdown.Item>
              <strong>Richtigkeitsbestätigungen</strong>
            </Dropdown.Item>
          </a>
          <Dropdown.Divider />
          <a
            className="header-link item"
            href="https://webgis.lisag.ch/PDF/%C3%96REB-Kataster-Weisungen/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Dropdown.Item>
              <strong>Weisungen ÖREB-Kataster</strong>
            </Dropdown.Item>
          </a>
          <Dropdown.Divider />
          {apo && (
            <a
              className="header-link item"
              href="https://www.ur.ch/amtsblatt"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Dropdown.Item>
                <strong>Amtsblatt Kanton Uri</strong>
              </Dropdown.Item>
            </a>
          )}
          {apo && <Dropdown.Divider />}
          <a
            className="header-link item"
            href="https://www.cadastre.ch/de/manual-oereb/modell/mini.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Dropdown.Item>
              <strong>Datenmodelle Bund</strong>
            </Dropdown.Item>
          </a>

          <Dropdown.Divider />
          <a
            className="header-link item"
            href="https://webgis.lisag.ch/PDF/Datenmodelle/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Dropdown.Item>
              <strong>Datenmodelle Kanton</strong>
            </Dropdown.Item>
          </a>
          <a
            className="header-link item"
            href="https://webgis.lisag.ch/PDF/Datenmodelle/T-Geodatmod-NP_Doku_141111.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Dropdown.Item>- Nutzungsplanung</Dropdown.Item>
          </a>
          <a
            className="header-link item"
            href="https://webgis.lisag.ch/PDF/Datenmodelle/Modelldokumentation_Planungszonen_V1.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Dropdown.Item>- Planungszonen</Dropdown.Item>
          </a>
          <a
            className="header-link item"
            href="https://webgis.lisag.ch/PDF/Datenmodelle/Planerischer_Gewaesserschutz_ID130,131,132_20171023_V1.1_d.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Dropdown.Item>- Planerischer Gewässerschutz</Dropdown.Item>
          </a>
          <a
            className="header-link item"
            href="https://webgis.lisag.ch/PDF/Datenmodelle/Kataster_der_belasteten_Standorte__ID115.2,116,117,118,119__V1.2_d.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Dropdown.Item>- Kataster der belasteten Standorte</Dropdown.Item>
          </a>
          <Dropdown.Divider />
          <a
            className="header-link item"
            href="https://www.lisag.ch"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Dropdown.Item>
              <strong>Kataster verantwortliche Stelle</strong>
            </Dropdown.Item>
          </a>
          <Dropdown.Divider />
          <a
            href="mailto:mail@lisag.ch?subject=Beglaubigter ÖREB-Auszug"
            className="header-link item"
          >
            <Dropdown.Item>
              <p>
                <strong>Beglaubigte Auszüge</strong>
              </p>
              Für beglaubigte Auszüge wenden Sie sich
              <br />
              bitte an die Lisag AG. <br />
              (Tel +41 500 60 60, E-Mail: mail@lisag.ch)
            </Dropdown.Item>
          </a>
          <Dropdown.Divider />
          <a
            className="header-link item"
            href="https://geo.ur.ch/viewer"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Dropdown.Item>
              <strong>Geodaten des Kantons Uri -> GEO.UR</strong>
            </Dropdown.Item>
          </a>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
const mapStateToProps = ({ device, apo }) => ({
  device,
  apo
});
export default connect(mapStateToProps)(HeaderLinks);
HeaderLinks.propTypes = {
  device: PropTypes.string.isRequired,
  dispatch: PropTypes.func
};
