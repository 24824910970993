import { getWfsResult } from "./requestUtil";
import { displayGeojson } from "./geojsonUtil";
import urlUtil from "./urlUtil";
import transform from "./coordTransformUtil";
import { maps, popupUtils } from "./mapStateUtil";
import L from "leaflet";

/**
 * display the popup and highlight parcel based on search or query params.
 * @param {object} config - {searchParams:{"like urlUtil.getCurrentParams()"}, withPopup: boolean}
 */
export const displayPopupFromSearchParams = (
  config = { searchParams: null, withPopup: false }
) => {
  const { searchParams, withPopup } = config;
  if (searchParams.egrid) {
    getWfsResult("suche:all_egrid_data", "egris_egrid", searchParams.egrid)
      .then(parcel => {
        //update the url in case no popup will be displayed
        urlUtil.updateSearchParams(
          urlUtil.getNewSearchParams(parcel.features[0].properties)
        );
        displayGeojson({
          geojson: parcel.features[0],
          maps: maps,
          flyToBounds: true
        }).then(layer => {
          if (withPopup) {
            popupUtils.forEach(popupUtil => {
              popupUtil.displayPopup({
                requestResult: parcel,
                type: "egrid",
                position: layer.getBounds().getCenter()
              });
            });
          }
        });
      })
      .catch(error => alert(error));
    return;
  }
  if (searchParams.adresse) {
    getWfsResult("suche:adressen", "searchterm", searchParams.adresse)
      .then(adresse => {
        urlUtil.updateSearchParams(
          urlUtil.getNewSearchParams(adresse.features[0].properties)
        );
        displayGeojson({
          geojson: adresse.features[0],
          maps: maps,
          flyToBounds: true
        }).then(layer => {
          if (withPopup) {
            popupUtils.forEach(popupUtil => {
              popupUtil.displayPopup({
                requestResult: adresse,
                type: "adresse",
                position: layer.getBounds().getCenter()
              });
            });
          }
        });
      })
      .catch(error => alert(error));
    return;
  }
  if (searchParams.gemeinde) {
    getWfsResult(
      "suche:t11_gemeindegrenze_ims_diss",
      "searchterm",
      searchParams.gemeinde
    )
      .then(gemeinde => {
        urlUtil.updateSearchParams(
          urlUtil.getNewSearchParams(gemeinde.features[0].properties)
        );
        displayGeojson({
          geojson: gemeinde.features[0],
          maps: maps,
          flyToBounds: true
        }).then(layer => {
          if (withPopup) {
            popupUtils.forEach(popupUtil => {
              popupUtil.displayPopup({
                requestResult: gemeinde,
                type: "gemeinde",
                position: layer.getBounds().getCenter()
              });
            });
          }
        });
      })
      .catch(error => alert(error));
    return;
  }
  if (searchParams.flurname) {
    getWfsResult("suche:t07_flurname", "searchterm", searchParams.flurname)
      .then(flurname => {
        urlUtil.updateSearchParams(
          urlUtil.getNewSearchParams(flurname.features[0].properties)
        );
        displayGeojson({
          geojson: flurname.features[0],
          maps: maps,
          flyToBounds: true
        }).then(layer => {
          if (withPopup) {
            popupUtils.forEach(popupUtil => {
              popupUtil.displayPopup({
                requestResult: flurname,
                type: "flurname",
                position: layer.getBounds().getCenter()
              });
            });
          }
        });
      })
      .catch(error => alert(error));
    return;
  }
  if (searchParams.coordinate) {
    // extract the coordinates
    const en = searchParams.coordinate.split(",");
    const e = en[0];
    const n = en[1];
    const lat = transform.LV95toWGSlat(e, n);
    const lng = transform.LV95toWGSlng(e, n);
    // create a geojson
    const geojson = L.marker([lat, lng]).toGeoJSON();
    geojson.properties.searchterm = `Koordinate LV95:<br /> E: ${
      en[0]
    } <br /> N: ${en[1]}`;
    /*
     * We have to format the request result like an ordinary wfs request result
     * in order for the display popup function to work.
     */
    const requestResult = { features: [geojson] };

    displayGeojson({
      geojson: geojson,
      maps: maps,
      flyToBounds: true
    }).then(layer => {
      if (withPopup) {
        popupUtils.forEach(popupUtil => {
          popupUtil.displayPopup({
            requestResult: requestResult,
            type: "coordinate",
            position: layer.getBounds().getCenter()
          });
        });
      }
    });

    return;
  }
  if (searchParams.lat && searchParams.lng && searchParams.zoom) {
    maps[0].setView([searchParams.lat, searchParams.lng], searchParams.zoom);
  }
};
