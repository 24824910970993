import React from "react";
import PropTypes from "prop-types";
import L from "leaflet";
import "leaflet.sync";
import { createBasemapSwitch } from "../utils/basemapUtil";
import { createLegendButton } from "../utils/legendUtil";
import { createLefleatLayer, getOnLayers } from "../utils/layerUtil";
import {
  createProjektperimeterOvelay,
  displayGeojson
} from "../utils/geojsonUtil";
import { maps } from "../utils/mapStateUtil";
import { getFeatureInfo } from "../utils/requestUtil";
import PopupUtil from "../utils/popupUtil";
import "../../node_modules/leaflet/dist/leaflet.css";
import "./Auflage.css";

class AuflageMap extends React.Component {
  constructor(props) {
    super(props);
    this.registerClickEvent = this.registerClickEvent.bind(this);
    this.id = this.props.layergroup.id.replace(/\s/g, "_");
  }
  registerClickEvent({ map = null, layers = [], popupUtil = null } = {}) {
    map.on("click", event => {
      if (map.geojson) {
        map.geojson.remove();
      }
      const layerStrings = getOnLayers(layers);
      getFeatureInfo(event, map, layerStrings)
        .then(geojson => {
          if (geojson) {
            displayGeojson({
              geojson: geojson.features[0],
              maps: [map],
              flyToBounds: false,
              removeExisting: false
            }).then(layer => {
              popupUtil.displayPopup({
                requestResult: geojson,
                type: "layer",
                position: event.latlng
              });
            });
          }
        })
        .catch(error => console.info(error));
    });
  }
  componentDidMount() {
    const { perimeter, layergroup, displayMetadata } = this.props;
    this.map = L.map(`${this.id}`, {
      center: [46.862, 8.632],
      zoom: 13,
      minZoom: 10,
      zoomControl: false // we add it later to bottomright
    });
    // the popup for the map with the projected layers
    const popup = new L.popup();
    popup.on("remove", () => {
      this.map.geojson.remove();
    });
    const popuputil = new PopupUtil({
      popup: popup,
      dispatch: null,
      map: this.map
    });

    this.registerClickEvent({
      map: this.map,
      layers: layergroup.layers,
      popupUtil: popuputil
    });

    const basemapControl = createBasemapSwitch({
      position: "topright",
      activeBasemap: "AV",
      apo: true
    });

    const legendControl = createLegendButton({
      position: "topright",
      layers: layergroup.layers,
      displayMetadata: displayMetadata
    });

    basemapControl.addTo(this.map);
    legendControl.addTo(this.map);

    L.control.zoom({ position: "bottomright" }).addTo(this.map);
    const periLayer = createProjektperimeterOvelay(perimeter);
    this.map.fitBounds(periLayer.getBounds());
    periLayer.addTo(this.map);

    layergroup.layers.map(layer => {
      layer.opacity = 80;
      return createLefleatLayer(layer).addTo(this.map);
    });

    // add them to the array of maps for later syncronisation
    maps.push(this.map);
  }

  render() {
    return (
      <div className="comparemap__container">
        <div
          className={
            this.props.layergroup.projected
              ? "comparemap__groupname comparemap__groupname-projected"
              : "comparemap__groupname"
          }
        >
          {this.props.layergroup.id}
        </div>
        <div className="comparemap__map" id={`${this.id}`} />
      </div>
    );
  }
}

export default AuflageMap;

AuflageMap.propTypes = {
  perimeter: PropTypes.object.isRequired,
  layergroup: PropTypes.object.isRequired,
  displayMetadata: PropTypes.func.isRequired
};
