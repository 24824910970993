import { GET_GROUPS, UPDATE_GROUP } from "./layerGroupActions";

const groupLayers = (state = layerGroups, action) => {
  const { type } = action;
  switch (type) {
    case GET_GROUPS:
      return state;
    case UPDATE_GROUP:
      if (action.status === "show") {
        action.group.visibleLayers = action.group.visibleLayers + 1;
        if (action.group.visibleLayers === action.group.sublayers.length) {
          action.group.allOn = true;
        }
      }
      if (action.status === "hide") {
        action.group.visibleLayers = action.group.visibleLayers - 1;
        action.group.allOn = false;
      }
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.group.id]: action.group
        }
      };
    default:
      return state;
  }
};

export default groupLayers;

const layerGroups = {
  byId: {
    "PLANUNGSZONE KANTONAL": {
      id: "PLANUNGSZONE KANTONAL",
      hasLayers: false,
      visibleLayers: 0,
      allOn: false,
      expanded: false,
      sublayers: ["Kantonale Planungszonen"]
    },
    "PLANUNGSZONE KOMMUNAL": {
      id: "PLANUNGSZONE KOMMUNAL",
      hasLayers: true,
      visibleLayers: 0,
      allOn: false,
      expanded: false,
      sublayers: ["Gemeindliche Planungszonen"]
    },
    "NUTZUNGSPLANUNG KANTONAL": {
      id: "NUTZUNGSPLANUNG KANTONAL",
      hasLayers: false,
      visibleLayers: 0,
      allOn: false,
      expanded: false,
      sublayers: ["Kantonale Nutzungsplanung", "Kantonale Baulinien"]
    },
    "NUTZUNGSPLANUNG KOMMUNAL": {
      id: "NUTZUNGSPLANUNG KOMMUNAL",
      hasLayers: true,
      visibleLayers: 0,
      allOn: false,
      expanded: false,
      sublayers: [
        "Grundnutzung",
        "Gefahrenzonen",
        "Flächenbezogene Festlegungen",
        "Linienbezogene Festlegungen",
        "Punktbezogene Festlegungen"
      ]
    },
    SONDERNUTZUNGSPLANUNG: {
      id: "SONDERNUTZUNGSPLANUNG",
      hasLayers: true,
      visibleLayers: 0,
      allOn: false,
      expanded: false,
      sublayers: [
        "Quartierpläne",
        "Quartiergestaltungspläne",
        "Gemeindliche Baulinien"
      ]
    },
    LÄRM: {
      id: "LÄRM",
      hasLayers: true,
      visibleLayers: 0,
      allOn: false,
      expanded: false,
      sublayers: ["Lärmempfindlichkeitsstufen (in Nutzungszonen)"]
    },
    WALD: {
      id: "WALD",
      hasLayers: true,
      visibleLayers: 0,
      allOn: false,
      expanded: false,
      sublayers: ["Statische Waldgrenzen", "Waldabstandslinien"]
    },
    GRUNDWASSERSCHUTZ: {
      id: "GRUNDWASSERSCHUTZ",
      hasLayers: true,
      visibleLayers: 0,
      allOn: false,
      expanded: false,
      sublayers: ["Grundwasserschutzzonen", "Grundwasserschutzareale"]
    },
    STRASSEN: {
      id: "STRASSEN",
      hasLayers: true,
      visibleLayers: 0,
      allOn: false,
      expanded: false,
      sublayers: [
        "Baulinien Nationalstrassen",
        "Projektierungszonen Nationalstrassen"
      ]
    },
    EISENBAHNEN: {
      id: "EISENBAHNEN",
      hasLayers: false,
      visibleLayers: 0,
      allOn: false,
      expanded: false,
      sublayers: [
        "Baulinien Eisenbahnanlagen",
        "Projektierungszonen Eisenbahnanlagen"
      ]
    },
    FLUGHÄFEN: {
      id: "FLUGHÄFEN",
      hasLayers: false,
      visibleLayers: 0,
      allOn: false,
      expanded: false,
      sublayers: [
        "Baulinien Flughafenanlagen",
        "Projektierungszonen Flughafenanlagen",
        "Sicherheitszonenplan"
      ]
    },
    "KATASTER BELASTETER STANDORTE": {
      id: "KATASTER BELASTETER STANDORTE",
      hasLayers: true,
      visibleLayers: 0,
      allOn: false,
      expanded: false,
      sublayers: [
        "KbS",
        "KbS Bund - Militär",
        "KbS Bund - zivile Flugplätze",
        "KbS Bund - öffentlicher Verkehr"
      ]
    },
    "NUTZUNGSPLANUNG KOMMUNAL PROJEKTIERT": {
      apo: true,
      id: "NUTZUNGSPLANUNG KOMMUNAL PROJEKTIERT",
      hasLayers: true,
      visibleLayers: 0,
      allOn: false,
      expanded: false,
      sublayers: [
        "Grundnutzung proj.",
        "Gefahrenzonen proj.",
        "Flächenbezogene Festlegungen proj.",
        "Linienbezogene Festlegungen proj.",
        "Punktbezogene Festlegungen proj."
      ]
    },
    "WALD PROJEKTIERT": {
      apo: true,
      id: "WALD PROJEKTIERT",
      hasLayers: true,
      visibleLayers: 0,
      allOn: false,
      expanded: false,
      sublayers: ["Statische Waldgrenzen proj.", "Waldabstandslinien proj."]
    },
    "LÄRM PROJEKTIERT": {
      apo: true,
      id: "LÄRM PROJEKTIERT",
      hasLayers: true,
      visibleLayers: 0,
      allOn: false,
      expanded: false,
      sublayers: ["Lärmempfindlichkeitsstufen (in Nutzungszonen) proj."]
    },
    "GRUNDWASSERSCHUTZ PROJEKTIERT": {
      apo: true,
      id: "GRUNDWASSERSCHUTZ PROJEKTIERT",
      hasLayers: true,
      visibleLayers: 0,
      allOn: false,
      expanded: false,
      sublayers: ["Grundwasserschutzzonen proj."]
    }
  },
  allIds: [
    "PLANUNGSZONE KANTONAL",
    "PLANUNGSZONE KOMMUNAL",
    "NUTZUNGSPLANUNG KANTONAL",
    "NUTZUNGSPLANUNG KOMMUNAL",
    "SONDERNUTZUNGSPLANUNG",
    "LÄRM",
    "WALD",
    "GRUNDWASSERSCHUTZ",
    "STRASSEN",
    "EISENBAHNEN",
    "FLUGHÄFEN",
    "KATASTER BELASTETER STANDORTE",
    "NUTZUNGSPLANUNG KOMMUNAL PROJEKTIERT",
    "WALD PROJEKTIERT",
    "LÄRM PROJEKTIERT",
    "GRUNDWASSERSCHUTZ PROJEKTIERT"
  ]
};
