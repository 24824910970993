import React from "react";
import ApoHeader from "./ApoHeader";
import OerebHeader from "./OerebHeader";
const Header = ({ apo }) => {
  if (apo) {
    return <ApoHeader />;
  } else {
    return <OerebHeader />;
  }
};

export default Header;
