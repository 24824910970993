import React from "react";
import { Segment, Table } from "semantic-ui-react";

const MetadataContent = props => {
  const {
    name,
    legende,
    beschrieb,
    id,
    genauigkeit,
    aktualisierung,
    zeitstand,
    exception
  } = props.metadata;
  return (
    <div>
      <h2>{name}</h2>
      <h4>Legende:</h4>
      {legende !== "Keine Legende verfügbar." && legende && (
        <img
          data-testid="legend-image"
          src={legende}
          alt="Legende"
          className="legend"
        />
      )}
      {legende === "Keine Legende verfügbar." && <Segment>{legende}</Segment>}

      <h4>Kurzbeschrieb:</h4>
      {beschrieb && <Segment data-testid="kurzbeschrieb">{beschrieb}</Segment>}

      {id && (
        <section>
          <h4>Weitere Informationen:</h4>
          <Table striped celled>
            <Table.Body>
              <Table.Row>
                <Table.Cell>ID Geobasisdatensatz:</Table.Cell>
                <Table.Cell data-testid="datensatz-id">{id}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Erfassungsgenauigkeit:</Table.Cell>
                <Table.Cell data-testid="datensatz-genauigkeit">
                  {genauigkeit}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Aktualisierungsrhytmus:</Table.Cell>
                <Table.Cell data-testid="datensatz-aktualisierung">
                  {aktualisierung}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Letzte Aktualisierung:</Table.Cell>
                <Table.Cell data-testid="datensatz-zeitstand">
                  {zeitstand}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </section>
      )}
      {exception && <div data-testid="exception">{exception}</div>}
    </div>
  );
};

export default MetadataContent;
