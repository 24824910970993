import React from "react";
import { Highlight } from "react-instantsearch-dom";
import { Icon } from "semantic-ui-react";
import polygon from "../assets/img/glyphicons-97-vector-path-polygon_small.png";
import PropTypes from "prop-types";

const Hit = ({ hit }) => {
  const image =
    hit.groupname === "Adressen" ? (
      <Icon name="map marker alternate" />
    ) : (
      <img src={polygon} alt="polygon" />
    );
  if (hit.groupname === "apo_perimeter") {
    return (
      <div>
        <strong>
          <Highlight attribute="searchterm" hit={hit} tagName="mark" />
        </strong>
        <br />
        <strong>Status: </strong>
        <Highlight attribute="statusaktuell" hit={hit} tagName="mark" />
        <br />
        <strong>Beschluss: </strong>
        <Highlight attribute="beschlussname" hit={hit} tagName="mark" />
        <br />
        <strong>Beschluss Datum: </strong>
        <Highlight attribute="beschluss_datum" hit={hit} tagName="mark" />
        <br />
        <strong>Zuständig: </strong>
        <Highlight attribute="zustaendigestelle" hit={hit} tagName="mark" />
        <br />
        <strong>ÖREB-Thema: </strong>
        <Highlight attribute="oerebthema" hit={hit} tagName="mark" />
      </div>
    );
  }

  return (
    <div>
      {image} <Highlight attribute="searchterm" hit={hit} tagName="mark" />
      {hit.egris_egrid && <small> ({hit.egris_egrid})</small>}
    </div>
  );
};

export default Hit;

Hit.propTypes = {
  hit: PropTypes.object.isRequired
};
