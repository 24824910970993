import {
    WFS_BASE_URL,
    GET_FEATURE_INFO_BASE_URL,
    PDF_REQUEST_BASE_URL,
    JSON_REQUEST_BASE_URL
} from "./ENV";

let apo_polygons = null;
export let featureInfoRequestResult = null;

/**
 * @description Searches a parcel by wfs request. This function uses a wfs request therefore no coord transformation is necessary.
 * @param {string} service - the namespace and name of the service in the format: av:liegenschaften.
 * @param {string} filterAttribute - the name of the attribute column to search for the searchterm.
 * @param {string} searchterm - spezific value to search.
 * @return {promise}
 */
export const getWfsResult = (service, filterAttribute, searchterm) => {
    let wfs_url = WFS_BASE_URL;
    if (typeof searchterm === "undefined" || searchterm === "") {
        wfs_url += "&typeName=" + service + "&outputFormat=json";
    } else {
        wfs_url += "&typeName=" + service;
        wfs_url +=
            "&CQL_FILTER=" +
            filterAttribute +
            "=%27" +
            searchterm +
            "%27&outputFormat=json";
    }
    return fetch(wfs_url)
        .then(response => response.json())
        .then(json => {
            featureInfoRequestResult = json.features;
            return json.totalFeatures > 0
                ? json
                : Promise.reject(
                      "Für den angeforderten Suchbegriff '" +
                          filterAttribute +
                          " " +
                          searchterm +
                          "', konnten keine Daten gefunden werden. " +
                          "Bitte überprüfen Sie Ihre Eingabe."
                  );
        })
        .catch(error => Promise.reject(error));
};

/**
 * perform a WMS getFeatureInfo request on allEgridData Feature Class.
 * @param {object} event - The original map click event.
 * @param {object} map - the leaflet map object.
 * @param {string} layers - string with layers to query featureInfos.
 * @return {promise}
 */
export const getFeatureInfo = (event, map, layers = "suche:all_egrid_data") => {
    return fetch(getFeatureInfoUrl(event, map, layers))
        .then(response => response.json())
        .then(json => {
            featureInfoRequestResult = json.features;
            return json.features.length > 0
                ? json
                : Promise.reject(
                      "🤔 An dieser Stelle gibt es keine Objektinformationen."
                  );
        })
        .catch(error => {
            console.info(error);
        });
};

/**
 * perform a request to order a static oereb (pdf).
 * @param {string} egrid - egrid number of the parcel.
 * @return {promise} - blob Object (pdf)
 */
export const orderOerebPdf = egrid => {
    const url = PDF_REQUEST_BASE_URL + egrid + "?response=mimetype";
    return fetch(url)
        .then(response => response.blob())
        .catch(error =>
            Promise.reject(
                "🤔Es gab einen Netzwerkfehler beim Anfordern des PDF's."
            )
        );
};

/**
 * perform a request to order a dynamic oereb.
 * @param {string} egrid - egrid number of the parcel.
 * @return {promise} - blob Object (pdf)
 */
export const orderOerebJson = egrid => {
    const url =
        JSON_REQUEST_BASE_URL + egrid + "?response=mimetype&withImages=false";
    return fetch(url)
        .then(response => response.json())
        .catch(error =>
            Promise.reject(
                "🤔Es gab einen Netzwerkfehler beim Anfordern der Daten.\n Bitte melden Sie sich bei der Lisag AG. Tel: 041 500 60 60."
            )
        );
};

/**
 * Get The relevant Polygons to check if there is an entry in the 'Amtliches Publikations Organ'
 * @return A Promise Object with the result json.
 */
export const getAPOpolygons = layername => {
    if (apo_polygons) {
        return Promise.resolve(apo_polygons);
    }
    const url = WFS_BASE_URL + "&typeName=" + layername + "&outputFormat=json";
    return fetch(url)
        .then(response => response.json())
        .then(json => {
            apo_polygons = json;
            return json;
        })
        .catch(error =>
            Promise.reject(
                "🤔 Es gab einen Netzwerkfehler beim Anfordern der Projektperimeter. Bitte versuchen Sie es später nochmal."
            )
        );
};

/**
 * Creates the right wms getFeatureInfo request url.
 * @param {object} event - The original map click event.
 * @param {object} map - the leaflet map object.
 * @param {string} layers - string of layers to getFeatureInfo.
 * @return {string} - the url to perform the request.
 */
const getFeatureInfoUrl = (event, map, layers) => {
    return (
        GET_FEATURE_INFO_BASE_URL +
        `&SRS=EPSG:4326` +
        `&BBOX=${map.getBounds().toBBoxString()}` +
        `&QUERY_LAYERS=${layers}` +
        `&FEATURE_COUNT=10` +
        `&LAYERS=${layers}` +
        `&WIDTH=${map.getSize().x}` +
        `&HEIGHT=${map.getSize().y}` +
        `&INFO_FORMAT=application%2Fjson` +
        `&X=${parseInt(event.containerPoint.x, 10)}` +
        `&Y=${parseInt(event.containerPoint.y, 10)}`
    );
};
