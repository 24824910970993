import React from "react";
import HeaderLinks from "./HeaderLinks";
import "./Header.css";
import headerLogo from "../assets/img/uri_logo.png";
const ApoHeader = () => {
  return (
    <header className="app-header">
      <div className="header-logo">
        <img src={headerLogo} alt="logo of the canton of Uri" />
      </div>
      <div className="apo-header-text-box">
        <h2 data-testid="apo-header-title" className="apo-header-title">
          AMTLICHES PUBLIKATIONSORGAN
        </h2>
        <span data-testid="apo-header-text" className="apo-header-text">
          Für öffentlich-rechtliche Eigentumsbeschränkungen.
          <br />
          Hier sehen sie projektierte Zustände der ÖREB-Kataster Daten.
        </span>
        <br />
        <a
          className="apo-header-text-link"
          href="https://www.cadastre.ch/de/oereb.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <strong> Mehr erfahren</strong>
        </a>
      </div>
      <HeaderLinks />
    </header>
  );
};

export default ApoHeader;
