import { SET_APO, SET_APO_POLYGONS } from "./apoActions";

export const apo = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case SET_APO:
      return action.value;
    default:
      return state;
  }
};

export const apoPolygons = (state = null, action) => {
  const { type } = action;
  switch (type) {
    case SET_APO_POLYGONS:
      return action.apoPolygons;
    default:
      return state;
  }
};
