import { orderOerebPdf, orderOerebJson } from "./requestUtil";
import { showModal, hideModal } from "../modal/modalActions";
import { setApo, setApoPolygons } from "../apo/apoActions";
import { toggleSidebar, setSidebarTab } from "../sidebar/sidebarActions";
import {
  showCurrentOverlay,
  displayGeojson,
  checkAPOIntersection
} from "./geojsonUtil";
import urlUtil from "./urlUtil";
import oerebParser from "./oerebUtil";
import { setOerebResult, setCurrentParcel } from "../oerebResult/oerebActions";
import { appendChildren, addClasses, visibleAttributes } from "./domUtil";

class PopupUtil {
  constructor(
    options = {
      popup: null, //leaflet popup instance
      dispatch: null, // redux dispatch function,
      map: null //leaflet map instance
    }
  ) {
    this.popup = options.popup;
    this.dispatch = options.dispatch;
    this.map = options.map;
    this.displayPopup = this.displayPopup.bind(this);
    this.getPopupContent = this.getPopupContent.bind(this);
    this.getEgridContent = this.getEgridContent.bind(this);
    this.getLayerContent = this.getLayerContent.bind(this);
  }

  /**
   * display the popup on the map.
   * @param {object} options.
   * @return {object} popup - a leaflet popup object.
   */
  displayPopup({ type = "egrid", requestResult = null, position = null } = {}) {
    if (this.dispatch) {
      this.dispatch(setCurrentParcel(requestResult.features[0]));
    }
    this.requestResult = requestResult;
    this.popup.setLatLng(position);
    this.popup.setContent(
      this.getPopupContent({
        type: type,
        requestResult: requestResult
      })
    );
    this.popup.openOn(this.map);
    return this.popup;
  }

  /**
   * creates the content for a popup based on it's type and requestResult.
   * @param {string} type - one of "egrid, adresse, gemeinde, flurname, latlng, apo"
   * @return {domNode} - a dom element with the content
   */
  getPopupContent(options = { type: "egrid", requestResult: null }) {
    let content = "";
    switch (options.type) {
      case "egrid":
        content = this.getEgridContent(options.requestResult);
        break;
      case "adresse":
      case "flurname":
      case "gemeinde":
      case "coordinate":
        content = options.requestResult.features[0].properties.searchterm;
        break;
      case "layer":
        content = this.getLayerContent(options.requestResult);
        break;
      default:
        alert("sorry, popup could not be created");
    }
    return content;
  }

  /**
   * creates the content for a egrid popup.
   * @param {object} requestResult - result of a getFeatureInfo or wfs request"
   * @return {domNode} - a dom element with the content
   */
  getEgridContent(requestResult) {
    const currentParcel = requestResult.features[0];
    const content = document.createElement("div");
    if (requestResult.features.length > 1) {
      const select = this.createSelect(requestResult.features);
      content.appendChild(select);
    }
    const title = this.createPopUpTitle(currentParcel.properties);
    // create a container for a potential apo button
    const apoButtonContainer = document.createElement("div");
    apoButtonContainer.id = "apo-button-container";
    addClasses(apoButtonContainer, ["red", "small"]);

    checkAPOIntersection(currentParcel)
      .then(response => {
        if (response.length > 0) {
          this.addApoButton(response);
        }
      })
      .catch(error => alert(error));

    const buttons = this.createOerebButtons(currentParcel);
    appendChildren(content, [title, buttons, apoButtonContainer]);
    return content;
  }

  /**
   * creates the content for a layer popup.
   * @param {object} requestResult - result of a getFeatureInfo or wfs request"
   * @return {domNode} - a dom element with the content
   */
  getLayerContent(requestResult) {
    const currentParcel = requestResult.features[0];
    const content = document.createElement("div");
    if (requestResult.features.length > 1) {
      const select = this.createSelect(requestResult.features);
      content.appendChild(select);
    }
    const title = this.createPopUpTitle(currentParcel.properties);
    const table = this.createPopupContentTable(
      visibleAttributes,
      currentParcel.properties
    );
    appendChildren(content, [title, table]);
    return content;
  }

  /**
   * creates the select of the popup in case multiple parcels were returned from getFeatureInfo.
   * @param {array} features - geojson array.
   * @return {domNode} - a div and a select inside.
   */
  createSelect(features) {
    const selectContainer = document.createElement("div");
    selectContainer.classList.add("select-container");
    const select = document.createElement("select");
    select.classList.add("select");
    features.forEach(feature => {
      const option = document.createElement("option");
      option.value = feature.id;
      option.innerHTML = feature.id;
      select.appendChild(option);
    });
    select.addEventListener("change", event => {
      const iwContainer = event.target.parentElement.parentElement;
      const table = iwContainer.querySelector("#pop-up-props");
      this.updatePopup({
        select: event.target,
        title: iwContainer.children[1],
        table: table
      });
    });
    selectContainer.appendChild(select);
    return selectContainer;
  }

  /**
   * creates the table with the attributes for the popup.
   * @param {array} parcelAttributes - attribute to NOT display.
   * @param {object} properties - the properties of the parcel.
   * @return {domNode} table.
   */
  createPopupContentTable(attributes, properties) {
    const table = document.createElement("table");
    table.id = "pop-up-props";
    table.classList.add(
      "ui",
      "unstackable",
      "compact",
      "celled",
      "striped",
      "table"
    );
    table.style.background = "rgba(255,255,255,0.1)";

    attributes.allIds.forEach(attribute => {
      const tr = document.createElement("tr");
      const title = document.createElement("td");
      const value = document.createElement("td");
      let titleText = attributes.byId[attribute];
      let valueText = properties[attribute];
      if (valueText) {
        switch (titleText) {
          case "Eigentümer":
            valueText = `<a href="${valueText}">Link Grundbuch</a>`;
            break;
          case "Fläche":
            valueText =
              parseInt(valueText, 10).toLocaleString("de-CH") + "m<sup>2</sup>";
            break;
          default:
        }

        title.innerHTML = "<strong>" + titleText + "</strong>";
        value.innerHTML = valueText;
        appendChildren(tr, [title, value]);
        table.appendChild(tr);
      }
    });
    return table;
  }

  /**
   * creates the buttons for ordering oerebs.
   * @return {domNode}
   */
  createOerebButtons(currentParcel) {
    const buttonContainer = document.createElement("div");
    const largeParcelContainer = document.createElement("div");
    buttonContainer.id = "button-container";
    largeParcelContainer.style.paddingBottom = "10px";

    // no buttons for projektierte liegenschaften
    if (currentParcel.properties.groupname !== "Projektierte Liegenschaften") {
      if (currentParcel.properties.shape_area > 500000) {
        largeParcelContainer.innerHTML = this.getLargeParcelText();
      }
      const staticButton = this.createStaticButton(
        currentParcel.properties.egris_egrid
      );
      const spacer = document.createElement("span");
      spacer.classList.add("btn-spacer");

      const dynamicButton = this.createDynamicButton(
        currentParcel.properties.egris_egrid
      );
      const spacer2 = document.createElement("span");
      spacer2.classList.add("btn-spacer");

      const grundbuchButton = this.createGrundbuchButton(
        currentParcel.properties.terrintra
      );
      if (largeParcelContainer.children.length > 0) {
        buttonContainer.appendChild(largeParcelContainer);
      }
      appendChildren(buttonContainer, [
        staticButton,
        spacer,
        dynamicButton,
        spacer2,
        grundbuchButton
      ]);
    }
    return buttonContainer;
  }

  getNoOerebText() {
    const text =
      "Da in dieser Gemeinde, gemäss Artikel 125 Absatz 2 PBG, " +
      "keine rechtskräftige Nutzungsplanung besteht und somit keine " +
      "rechtsgültigen digitalen Nutzungsplanungsdaten vorliegen, " +
      "ist eine ÖREB-Auswertung nicht möglich. Die Gemeinde hat aber " +
      "eine Planungszone gemäss Artikel 56 PBG erlassen und die " +
      "provisorischen Bau- und Nutzungsvorschriften festgelegt.";
    return text;
  }

  getLargeParcelText() {
    const text =
      "<span class='red small'>Diese Liegenschaft ist sehr gross. Eine ÖREB-Auswertung " +
      "kann sehr lange dauern und wird nicht empfohlen.</span>";
    return text;
  }

  /**
   * creates the button for ordering static (pdf) oerebs.
   * @return {domNode}
   */
  createStaticButton(egrid) {
    const staticButton = document.createElement("button");
    staticButton.id = "static-button";
    addClasses(staticButton, ["ui", "primary", "fluid", "button"]);
    staticButton.innerHTML = "ÖREB-Auswertung als Dokument (PDF)";
    staticButton.addEventListener("click", () => {
      this.dispatch(
        showModal("orderOereb", {
          title: "Statischer ÖREB-Auszug",
          thankText: "Vielen Dank für Ihre Bestellung!",
          text:
            "Der statische Auszug aus dem ÖREB-Kataster wird erstellt. Sobald das PDF generiert wurde, wird es in Ihrem Browser heruntergeladen."
        })
      );
      this.orderPdf(egrid);
    });
    return staticButton;
  }

  /**
   * creates the button for ordering dynamic (json) oerebs.
   * @return {domNode}
   */
  createDynamicButton(egrid) {
    const dynamicButton = document.createElement("button");
    addClasses(dynamicButton, ["ui", "primary", "fluid", "button"]);
    dynamicButton.innerHTML = "ÖREB-Auswertung am Bildschirm";
    dynamicButton.addEventListener("click", () => {
      this.dispatch(
        showModal("orderOereb", {
          title: "Dynamischer ÖREB-Auszug",
          thankText: "Vielen Dank für Ihr Interesse!",
          text:
            "Ihre Anfrage wird bearbeitet. Sobald die Auswertung fertig ist, werden die Resultate auf der linken Seite angezeigt."
        })
      );
      this.orderJson(egrid);
    });
    return dynamicButton;
  }

  createGrundbuchButton(terrintra) {
    const grundbuchButton = document.createElement("button");
    addClasses(grundbuchButton, ["ui", "fluid", "basic", "blue", "button"]);
    grundbuchButton.innerHTML = "Grundbuchauskunft";
    grundbuchButton.addEventListener("click", () => {
      window.location.href = terrintra;
    });
    return grundbuchButton;
  }

  /**
   * add the apo button to the popup.
   * @param {array} apoPolygons - the apo polygons that intersect the parcel
   * setTimeout is necessary because we have to wait until the popup
   * is displayed.
   */
  addApoButton(apoPolygons) {
    window.setTimeout(() => {
      const apoButtonContainer = document.getElementById(
        "apo-button-container"
      );
      const spacer = document.createElement("span");
      spacer.classList.add("btn-spacer");
      const apoText = document.createElement("div");
      apoText.classList.add("apoButtonContainer__hintText");
      apoText.innerHTML = "Zu dieser Liegenschaft gibt es projektierte Daten.";
      const apoPerimeters = this.getApoPopupInfos(apoPolygons);
      appendChildren(apoButtonContainer, [spacer, apoText, apoPerimeters]);
      this.popup.update(); //make sure the popup does not get obscured.
    }, 200);
  }

  getApoPopupInfos(apoPolygons) {
    const apoPerimeters = document.createElement("div");
    for (var i = 0; i < apoPolygons.length; i++) {
      const polygon = apoPolygons[i];
      if (
        polygon.properties.statusaktuell === "Öffentliche Auflage" ||
        polygon.properties.statusaktuell === "Festsetzung" ||
        polygon.properties.statusaktuell === "Genehmigung"
      ) {
        const apoButton = document.createElement("button");
        addClasses(apoButton, ["ui", "yellow", "fluid", "button"]);
        apoButton.style.color = "black";
        apoButton.innerHTML = "Zum amtlichen Publikationsorgan";
        apoButton.addEventListener("click", () => {
          this.dispatch(setApo(true));
          this.dispatch(setApoPolygons(apoPolygons));
          this.dispatch(toggleSidebar(false));
          urlUtil.toApo(polygon.properties.unique_key);
        });
        apoPerimeters.appendChild(apoButton);
        return apoPerimeters;
      }
      const table = document.createElement("table");
      table.classList.add(
        "ui",
        "unstackable",
        "yellow",
        "compact",
        "celled",
        "striped",
        "table"
      );
      table.style.background = "rgba(255,255,255,0.1)";
      const titleTr = document.createElement("tr");
      const titleText = document.createElement("td");
      const titleValue = document.createElement("td");
      titleText.innerHTML = "<strong>Projektname</strong>";
      titleValue.innerHTML = `${polygon.properties.projektname}`;
      const statusTr = document.createElement("tr");
      const statusText = document.createElement("td");
      const statusValue = document.createElement("td");
      statusText.innerHTML = "<strong>Rechtsstatus</strong>";
      statusValue.innerHTML = `${polygon.properties.statusaktuell}`;
      const themaTr = document.createElement("tr");
      const themaText = document.createElement("td");
      const themaValue = document.createElement("td");
      themaText.innerHTML = "<strong>ÖREB-Thema</strong>";
      themaValue.innerHTML = `${polygon.properties.oerebthema}`;
      appendChildren(titleTr, [titleText, titleValue]);
      appendChildren(themaTr, [themaText, themaValue]);
      appendChildren(statusTr, [statusText, statusValue]);
      appendChildren(table, [titleTr, statusTr, themaTr]);
      apoPerimeters.appendChild(table);
    }
    return apoPerimeters;
  }

  /**
   * updates the currentParcel of the model and updates redux state.
   * @param {string} key - the searchterm of the new parcel.
   */
  getCurrentParcel(key) {
    if (key) {
      const currentGeoJson = this.requestResult.features.filter(
        element => element.id === key
      );
      return currentGeoJson[0];
    } else {
      return null;
    }
  }
  /**
   * updates the popup when the select menu changes.
   * @param {object}  - select: the domNode of the select menu
                      - title: the domNode of the popupTitle
                      - table: the domNode of the popupTable
   */
  updatePopup({ select = null, title = null, table = null } = {}) {
    if (!select) {
      alert("Fehler beim Aktualisieren der Layer Informationen. 🤔");
      return;
    }
    const currentParcel = this.getCurrentParcel(select.value);
    if (this.dispatch) {
      this.dispatch(setCurrentParcel(currentParcel));
      this.updateSearchParams(currentParcel.properties);
    }
    this.map.geojson.remove();
    displayGeojson({
      removeExisting: false,
      geojson: currentParcel,
      maps: [this.map],
      flyToBounds: false
    }).then(layer => {
      const newTitle = this.createPopUpTitle(currentParcel.properties);
      const apoButtonContainer = document.getElementById(
        "apo-button-container"
      );
      if (apoButtonContainer) {
        /* remove any apo-buttons from a previous parcel and
         * check the apo intersection. This is only
         * done when apoButtonContainer is available
         * which is only when in oereb state.
         */
        apoButtonContainer.innerHTML = "";
        checkAPOIntersection(currentParcel)
          .then(response => {
            if (response.length > 0) {
              this.addApoButton(response);
            }
          })
          .catch(error => alert(error));
      }
      if (table) {
        const newTable = this.createPopupContentTable(
          visibleAttributes,
          currentParcel.properties
        );
        table.parentNode.replaceChild(newTable, table);
      }

      // add the oereb buttons if they are not available (proj. liegenschaften)
      const buttonContainer = document.getElementById("button-container");
      if (buttonContainer) {
        buttonContainer.innerHTML = "";
        const buttons = this.createOerebButtons(currentParcel);
        buttonContainer.appendChild(buttons);
      }
      title.parentNode.replaceChild(newTitle, title);
    });
  }

  /**
   * creates the title of the popup.
   * @param {object} properties - The attributes of the parcel.
   * @return {domNode} <h3>.
   */
  createPopUpTitle(properties) {
    const { groupname, gemeinde, nummer } = properties;
    const flaechenmass = parseInt(properties.flaechenmass).toLocaleString(
      "de-CH"
    );
    let title,
      subtitle = "";
    switch (groupname) {
      case "Liegenschaften":
        title = `Liegenschaft ${nummer} <small>(${flaechenmass}m<sup>2</sup>)</small>`;
        subtitle = `Gemeinde ${gemeinde}`;
        break;
      case "Projektierte Liegenschaften":
        title = `Projektierte Liegenschaft ${nummer} <small>(${flaechenmass}m<sup>2</sup>)</small>`;
        subtitle = `Gemeinde ${gemeinde}`;
        break;
      case "Selbstaendige Rechte":
        title = `Selbständiges Recht ${nummer} <small>(${flaechenmass}m<sup>2</sup>)</small>`;
        subtitle = `Gemeinde ${gemeinde}`;
        break;
      default:
        title = "Objekt Informationen:";
    }
    const titleContainer = document.createElement("div");
    const titleNode = document.createElement("h3");
    const subtitleNode = document.createElement("h4");
    titleNode.style.marginBottom = 0;
    subtitleNode.style.margin = 0;
    titleContainer.id = "pop-up-title";
    titleNode.innerHTML = title;
    subtitleNode.innerHTML = subtitle;
    appendChildren(titleContainer, [titleNode, subtitleNode]);
    return titleContainer;
  }

  orderPdf(egrid) {
    if (!egrid) {
      console.warn("Keine Egrid Nummer definiert");
      return;
    }
    orderOerebPdf(egrid)
      .then(blob => {
        this.dispatch(hideModal());
        let pdf = new Blob([blob], { type: "application/pdf" });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          //IE 11
          window.navigator.msSaveOrOpenBlob(pdf, "oereb_auszug.pdf");
        } else {
          let a = document.createElement("a");
          a.href = window.URL.createObjectURL(pdf);
          a.download = "oereb_auszug.pdf";
          // this is necessary for the firefox browser
          document.body.appendChild(a);
          a.click();
          // setTimeout is necessary in order that the anker tag
          // is not removed before it is opened.
          setTimeout(() => {
            document.body.removeChild(a);
          }, 100);
        }
      })
      .catch(error => alert(error));
  }

  orderJson(egrid) {
    if (!egrid) {
      console.warn("Keine Egrid Nummer definiert");
      return;
    }
    orderOerebJson(egrid)
      .then(json => {
        this.dispatch(hideModal());
        if (json.Message) {
          this.dispatch(
            showModal("orderFail", {
              title: "Sorry, es gab einen Fehler bei der Auswertung.",
              thankText:
                "Der Auswertungsserver konnte keine korrektes Resultat liefern.",
              text:
                "Bitte melden Sie sich bei der Lisag (Tel. 041 500 60 60), oder versuchen Sie es später nochmal.",
              exception: `${json.ExceptionMessage}`,
              exceptionType: `${json.ExceptionType}`
            })
          );
          return;
        }
        const searchParams = urlUtil.getCurrentParams();
        this.popup.remove();
        /*popup.remove() removes the url param and
         * the geojson. therefore we have to add
         * them again an set a new style
         */
        urlUtil.updateSearchParams(searchParams);
        showCurrentOverlay(this.map, {
          color: "black",
          fill: false,
          weight: 6
        });

        const oeparser = new oerebParser(json);
        oeparser
          .parseThemeWithoutData()
          .parseNotConcernedTheme()
          .parseTotalArea()
          .parseParcelNumber()
          .parseGemeinde()
          .parseRestrictions()
          .summarizeParsedRestrictions();
        console.log(oeparser.parsedResult);
        this.dispatch(setOerebResult(oeparser.parsedResult));
        this.dispatch(toggleSidebar(false));
        this.dispatch(setSidebarTab("results"));
      })
      .catch(error => alert(error));
  }

  /**
   * Updates the url based on geojson properties.
   * @param {object} properties - a geojson properties object.
   * @return {string} the new url parameters.
   */
  updateSearchParams(properties) {
    return urlUtil.updateSearchParams(urlUtil.getNewSearchParams(properties));
  }
}

export default PopupUtil;
