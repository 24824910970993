import { SET_OEREB_RESULT, SET_CURRENT_PARCEL } from "./oerebActions";
const initialState = null;

export const oerebResult = (state = initialState, action) => {
  switch (action.type) {
    case SET_OEREB_RESULT:
      return action.oerebResult;
    default:
      return state;
  }
};

export const currentParcel = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_PARCEL:
      return action.currentParcel;
    default:
      return state;
  }
};
