import React from "react";
import Autosuggest from "react-autosuggest";
import { connectAutoComplete } from "react-instantsearch-dom";
import Hit from "./Hit";
import { displayPopupFromSearchParams } from "../utils/searchUtil";
import urlUtil from "../utils/urlUtil";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import "./autocomplete.css";

class AutoComplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.currentRefinement };
    this.lv95Pattern = /^[2][0-9]{6}.[1][0-9]{6}/;
    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(
      this
    );
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(
      this
    );
    this.getSuggestionValue = this.getSuggestionValue.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.renderSectionTitle = this.renderSectionTitle.bind(this);
    this.debounceLoadSuggestions = this.debounceLoadSuggestions.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
  }

  componentDidMount() {
    this.input.focus();
  }
  onChange = (event, { newValue }) => {
    this.setState({ value: newValue });
  };
  onSuggestionsFetchRequested = ({ value }) => {
    if (this.lv95Pattern.test(value)) {
      const split = value[7];
      const x = value.split(split)[0];
      const y = value.split(split)[1].slice(0, 7);
      urlUtil.getNewSearchParams({
        groupname: "coordinate",
        coordinate: `${x},${y}`
      });
      displayPopupFromSearchParams({
        searchParams: { coordinate: `${x},${y}` },
        withPopup: this.props.withPopup
      });
    }
    this.debounceLoadSuggestions(value);
  };
  onSuggestionSelected = (e, { suggestion }) => {
    const aposearch = document.getElementsByClassName("apo-search")[0];
    if (aposearch) {
      window.scroll(0, aposearch.offsetTop - 10); //scroll down to see first map in auflage view.
    }
    switch (suggestion.groupname) {
      case "Gemeinde":
        displayPopupFromSearchParams({
          searchParams: {
            gemeinde: suggestion.searchterm
          },
          withPopup: this.props.withPopup
        });
        break;
      case "Flurnamen":
        displayPopupFromSearchParams({
          searchParams: { flurname: suggestion.searchterm },
          withPopup: this.props.withPopup
        });
        break;
      case "Adressen":
        displayPopupFromSearchParams({
          searchParams: {
            adresse: suggestion.searchterm
          },
          withPopup: this.props.withPopup
        });
        break;
      case "apo_perimeter":
        urlUtil.toApo(suggestion.unique_key);
        break;
      case "Liegenschaften":
      case "Projektierte Liegenschaften":
      case "Selbstaendige Rechte":
        displayPopupFromSearchParams({
          searchParams: {
            egrid: suggestion.egris_egrid
          },
          withPopup: this.props.withPopup
        });
        break;
      default:
        return null;
    }
    /*
     * remove focus on input.
     * the animation frame is necessary because when clicking
     * on a suggestion, the blur does not work correctly otherwise.
     */
    window.requestAnimationFrame(() => {
      this.input.blur();
    });
  };
  onSuggestionsClearRequested = () => this.props.refine();
  getSuggestionValue = hit => hit.searchterm;
  renderSuggestion = hit => <Hit hit={hit} />;
  renderSectionTitle = section => {
    if (section.hits.length > 0) {
      return section.index;
    } else {
      return null;
    }
  };
  getSectionSuggestions = section => section.hits;

  /* No need to pass the arguments to the debounce function.
   * They will be passed automatically
   * debounce returns a function that only executes once every 200ms.
   */
  debounceLoadSuggestions = debounce(this.props.refine, 100);

  storeInputReference = autosuggest => {
    if (autosuggest !== null) {
      this.input = autosuggest.input;
    }
  };

  render() {
    const { hits } = this.props;
    const { value } = this.state;
    const inputProps = {
      type: "search",
      placeholder: "Suche nach Liegenschaft, Adresse...",
      "aria-label": "Suche nach Adresse, Liegenschaften etc.",
      onChange: this.onChange,
      value
    };

    return (
      <Autosuggest
        suggestions={hits}
        multiSection={true}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        renderSectionTitle={this.renderSectionTitle}
        getSectionSuggestions={this.getSectionSuggestions}
        onSuggestionSelected={this.onSuggestionSelected}
        inputProps={inputProps}
        ref={this.storeInputReference}
      />
    );
  }
}
AutoComplete.propTypes = {
  withPopup: PropTypes.bool.isRequired,
  hits: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentRefinement: PropTypes.string.isRequired,
  refine: PropTypes.func.isRequired
};
export default connectAutoComplete(AutoComplete);
